@mixin transition($duration: 0.3s, $property: all, $effect: ease-in-out) {
  transition: $property $duration $effect;
}

@mixin animation($effect: fadeIn,  $duration: 0.5s, $delay: 0s) {
  animation-name: $effect;
  animation-duration: $duration;
  animation-fill-mode: both;
  animation-delay: ($delay);
}

@mixin info-blue-gradient {
background: rgb(199,249,237);
background: linear-gradient(111deg, rgba(199,249,237,0.7525385154061625) 33%, rgba(146,244,228,1) 100%);
}
@mixin palette($bg: red, $color: yellow) {
  background-color: $bg !important;
  fill: $bg !important;
  color: $color !important;
  h1, h2, h3, h4, .lead {
    color: $color;
    small {
      color: $color;
    }
  }
  hr {
    border-color: lighten($color, 10%) !important;
  }
}

@mixin clamp($num: 2, $line-height: 1.2) {
  display: block;
  display: -webkit-box;
  position: relative;

  max-height: 1em * $line-height * $num;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;

  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
  // &:after {
  //   display    : block;
  //   position   : absolute;
  //   height     : 1em * $line-height;
  //   content    : '...';
  //   text-align : right;
  //   bottom     : 0;
  //   right      : 0;
  //   // width      : 25%;
  //   background : linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 75%);
  // }
}

// Add responsive CSS
//
// Use this mixin to wrap CSS that gets applied to only certain devices.  This
// mixin will target screen devices with min-width = the min width associated with $min-size,
// and max-width = the max width associated with #max-size.  Use 0 to skip a
// min/max size.  Use 1 argument to set both min and max size together.
//
// Examples:
//   - respond-to(phone) -- target phones
//   - respond-to(tablet, laptop) -- target devices with sizes between tablets and small desktops (inclusive)
//   - respond-to(0, laptop) -- target devices with sizes tablets and below
//

@mixin respond-to($min-size, $max-size: null) {
  @if($max-size == null) {
    $max-size: $min-size;
  }

  @if(map-has-key($breakpoints, $min-size)) {
    $min-size: map-get($breakpoints, $min-size);
  }
  @if(map-has-key($breakpoints-max, $max-size)) {
    $max-size: map-get($breakpoints-max, $max-size);
  }
  $query: 'only screen';

  @if($min-size != 0) {
    $query: "#{$query} and (min-width: #{$min-size})";
  }
  @if($max-size != 0) {
    $query: "#{$query} and (max-width: #{$max-size})";
  }

  @media #{$query} {
    @content;
  }
}

@mixin no-underline() {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin border-radius-6 {
  border-radius:6px;
}

@mixin border-radius-8 {
  border-radius:8px;
}

@mixin border-radius-12 {
  border-radius:12px;
}

@mixin border-radius-24 {
  border-radius:24px;
}

@mixin border-radius-36 {
  border-radius:36px;
}

@mixin border-radius-48 {
  border-radius:48px;
}

@mixin border-radius-top-only {
  border-top-left-radius:36px;
  border-top-right-radius:36px;
}

@mixin btn-hover-levitation-transform {
  transform: translate3d(0,-0.1em,0) scale(1.01);
}

@mixin box-shadow() {
  box-shadow:
  0 0.7px 2.2px rgba(0, 0, 0, 0.011),
  0 1.6px 5.3px rgba(0, 0, 0, 0.016),
  0 3px 10px rgba(0, 0, 0, 0.02),
  0 5.4px 17.9px rgba(0, 0, 0, 0.024),
  0 10px 33.4px rgba(0, 0, 0, 0.029),
  0 24px 80px rgba(0, 0, 0, 0.04);
}

@mixin box-shadow-darker() {
  box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07);
}

@mixin box-shadow-hover() {
box-shadow:
  0 1.1px 1.1px rgba(0, 0, 0, 0.012),
  0 2.5px 2.7px rgba(0, 0, 0, 0.017),
  0 4.8px 5px rgba(0, 0, 0, 0.02),
  0 8.5px 8.9px rgba(0, 0, 0, 0.024),
  0 15.9px 16.7px rgba(0, 0, 0, 0.029),
  0 38px 40px rgba(0, 0, 0, 0.04);
}

@mixin transition-none {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

@mixin box-shadow-btn() {
  box-shadow:
  1.4px 4.5px 3.1px rgba(0, 0, 0, 0.016),
  11px 36px 25px rgba(0, 0, 0, 0.03);
}

@mixin box-shadow-btn-hover() {
box-shadow:
    0.5px 1.6px 1.1px rgba(0, 0, 0, 0.012),
    1.4px 4.5px 3.1px rgba(0, 0, 0, 0.016),
    3.3px 10.9px 7.5px rgba(0, 0, 0, 0.02),
    11px 36px 25px rgba(0, 0, 0, 0.03);
}
@mixin shadow() {
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
}



@mixin font-h1 {
  font-size: $font-size-h1;
  line-height: 1.25;
  font-family: $headings-font-family;
}

@mixin font-h2 {
  font-size: $font-size-h2;
  line-height: 1.25;
  font-family: $headings-font-family;
}

@mixin font-h3 {
  font-size: $font-size-h3;
  line-height: 1.25;
  font-family: $headings-font-family;
}

@mixin font-weight-normal {
  font-weight: $font-weight-normal;
}

@mixin font-weight-bold {
  font-weight: $font-weight-bold;
}

@mixin font-weight-light {
  font-weight: $font-weight-light;
}

@mixin header-colors {
  @each $name, $color_info in $colorscheme {
    &.#{$name} {
      $color: nth($color_info, 1);
      $alternate: nth($color_info, 2);

      @include palette($color, $alternate);
      padding: 8px;
    }
  }
}

