#page-footer {
  overflow:hidden;
  h3 {
    font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    font-size:18px;
    line-height:36px;
    @include font-weight-bold;
    color: $Text;
  }
  .footer-group1 {
    padding: 5% 0;
    .pcf-link {
      text-decoration:none;
      color:$Text;
      &:hover{
        color:$lime;
        .pcf-logo {
          opacity:0.8;
        }
      }
      .pcf-logo-text {
        height: 63px;
        .pcf-logo {
          float: left;
          width: 73px;
          height: 63px;
          img {
            filter: contrast(1%);
          }
        }
      }
    }
    .social {
      li {
        display: block;
        float: left;
        padding: 12px;
      }
    }
    .contact li {
      width:210px;
      height: 40px;
      margin: 0 auto;
    }
    .contact p {
      font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
      font-size:14px;
      @media(max-width: $screen-sm-max) {
        font-size: 12px;
        line-height: 26px;
      }
    }
    .contact-svg {
      display: block;
      float: left;
      width: 29px;
      height: 29px;
      &.location-svg {
      height: 100px;
      }
      &.form-svg {
        height: 29px;
        margin-top: 9px;
      }
    }
  }
  .footer-group2 {
    padding: 3% 0 2% 0;
    border-top:1px solid $Grey400;
    #legalnav {
      line-height: 22px;
      font-size: 14px;
      p {
        color:$Grey100;
        margin-right:5px;
        float: left;
      }
      a {
        text-decoration: none;
        padding: 0 5px;
        border-left:1px solid $Grey100;
      }
    }
  }
  #footernav {
    float: right;
  }
  .navbar-nav {
    float: none;
  }
  .nav {
    li > a {
    font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    @include no-underline;
    @include font-weight-normal;
    color: #282828;
    &:hover {
      background-color: transparent;
    }
    position: relative;
    display: block;
    padding: 10px 0px;
    }
  }
  p {
    color: $charcoal;
  }
  i {
    color: $teal;
    font-size: 25px;
  }
}

#footer-logo {
  width: 250px;
  margin-bottom: 20px;
  &:hover {
    opacity:0.8;
  }
}

.pcf {
  line-height: 1;
  a {
    display: table;
    // margin: 0 auto;
    img, small {
      display: table-cell;
      padding: 0 5px;
      vertical-align: middle;
    }
  }
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {
  #page-footer {
    text-align: center;
    .footer-group1 {
      .footer-link-group {
        margin-top: 30px;
        min-height: 220px;
        .contact li {
          width: 155px;
        }
      }
      .pcf-logo-text {
        width:240px;
        margin: 10px auto;
        text-align:left;
        .pcf-logo {
          float: left;
          width: 73px;
          height: 63px;
        }
      }
      .social {
        width: 90%;
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
        padding-left: 16px;
      }
    }
    #footernav {
      float: none;
      ul.nav {
        display: inline-block;
        margin: 0 auto;
      }
    }
    .footer-group2 {
      #legalnav {
        p {
          float: none;
        }
        a {
          @include font-weight-normal;
          border-left:none;
          display: block;
        }
      }
      .staff-controls {
        margin-top:20px;
      }
    }
  }
  #footer-logo {
    margin: 0 auto;
  }

  .pcf {
    padding: 20px;
    a {
      margin: 0 auto;
    }
  }
}

@media(max-width: $screen-xs-max) {
  #page-footer {
    #footernav {
      ul {
        li {
          display: inline-block;
        }
      }
    }
  }
}
