@charset "utf-8";

@import "variables";

// 3rd party
@import "bootstrap";
@import "~select2/dist/css/select2";
@import "fontawesome";

@import "functions";
@import "mixins";
@import "utilities";
@import "animations";

@import "base/global";
@import "base/typography";
@import "base/font_icons";
@import "base/fonts";
@import 'base/colors';

// Import layout / global styles
@import 'layout/base';
@import "layout/page_header";
@import "layout/page_content";
@import "layout/page_footer";
@import "layout/utils";

// External Libraries
@import "libs/venobox";

// Import additional elements or project specific styles
@import "elements/searchbar";
@import "elements/navigation";
@import "elements/tabs";
@import "elements/illustrations";
@import "elements/canvas";
@import "elements/slider";
@import "elements/blockquotes";
@import "elements/dialogs";
@import "elements/buttons";
@import "elements/forms";
@import 'elements/multi_step_pane';
@import "elements/video_card";
@import "elements/login";
@import "elements/alerts";
@import "elements/sitemap";
@import "elements/consolidate_header";
@import "elements/language_switcher";
@import 'elements/select';
@import 'elements/tables';
@import 'elements/pricing-table';
@import 'elements/dropdowns';
@import 'elements/accordion';
@import 'elements/breadcrumb';
@import 'elements/cookie_consent';
@import 'elements/switch_fields';
@import 'elements/tooltip';

// Views
@import "views/home";
@import "views/platform";
@import "views/services";
@import "views/about";
@import "views/volunteer";
@import "views/recruitment";
@import "views/amplifying-voices";
@import "views/contact-us";

// TODO - Refactor these styles into partials

// for internal pages with an intro section
.intro {
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2 {
    margin-top: 10px;
  }
  p {
    margin-top: 35px;
    margin-bottom: 30px;
  }
}

// for the plus and pro ToS pages
.bullets {
  padding-left: 40px;
  list-style-type: disc;
  list-style-position: outside;
}

.tosTable {
  width: 400px;
  height: 200px;

  .text-charcoal {
      color: $charcoal !important;
  }
}

/******************************************/
/***     carousel - BEGIN               ***/

#marketing-testimonials
{
  padding: 0 10px 30px 10px;
  .carousel-inner {
    min-height: 340px;
  }
}

#marketing-testimonials .carousel-control
{
  background: none;
  color: #222;
  font-size: 2.3em;
  text-shadow: none;
  margin-top: 4%;
}

#marketing-testimonials .carousel-control.left
{
  left: -12px;
}

#marketing-testimonials .carousel-control.right
{
  right: -12px !important;
}

#marketing-testimonials .carousel-indicators
{
  display: none;
  right: 50%;
  top: auto;
  bottom: 0px;
  margin-right: -19px;
}

#marketing-testimonials .carousel-indicators li
{
  background: #c0c0c0;
}
#marketing-testimonials .carousel-indicators .active
{
  background: #333333;
}
#marketing-testimonials img
{
  width: 250px;
  height: 100px
}

#marketing-testimonials .item p{
  color: #5a2747;
  font-size: 1.3em;
  font-style: italic;
}

#marketing-testimonials .item .bold{
  font-size: 16px;
  font-family: $first-level-headings-font-family;
  font-weight: bold;
  color: #000;
  font-style: normal;
}

#marketing-testimonials .item .small{
  font-size: 14px;
  color: #777;
  font-style: normal;
}

#marketing-testimonials .item blockquote {
    border-left: none;
    margin: 0;
    display: block;
    color: #000;
    @media(max-width: $screen-sm-max) {
      padding: 0 50px;
    }
}

#marketing-testimonials .item blockquote p:before {
  display: none;
}

#marketing-testimonials .item blockquote p:after {
  display: none;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #marketing-testimonials
    {
      margin-bottom: 0;
      padding: 0 40px 30px 40px;
    }

}

/* Small devices (tablets, up to 768px) */
@media (max-width: 768px) {

    /* Make the indicators larger for easier clicking with fingers/thumb on mobile */

    #marketing-testimonials .carousel-indicators {
        bottom: -20px !important;
    }
    #marketing-testimonials .carousel-indicators li {
        display: inline-block;
        margin: 0px 5px;
        width: 15px;
        height: 15px;
    }
    #marketing-testimonials .carousel-indicators li.active {
        margin: 0px 5px;
        width: 20px;
        height: 20px;
    }
  }

/*******************************************/
/***      ./ carousel - END              ***/
