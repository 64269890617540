#aod-services {
 .container {
    @include respond-to(tablet, laptop) {
      width: 100%;
    }
  }
  .aod-features-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    @include respond-to(phone, tablet) {
      margin-bottom: 25px;
      margin-left: 0;
    }
  }
  .title-illustr {
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  .cta-featured-services {
    text-align: left;
    @include respond-to(tablet) {
      text-align: center;
    }
    @include respond-to(phone) {
      text-align: center;
    }
    .title-underline {
      @include respond-to(phone, tablet) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .aod-feature-box {
    width: 19%;
    @include respond-to(tablet, laptop) {
      width: 30%;
      text-align: center;
    }
    @include respond-to(phone) {
      width: 50%;
      text-align: center;
    }
    .bio-card {
      min-height: 240px;
      height: auto;
      overflow: hidden;
      background: #fff;
      border-radius: 12px;
      margin: 8% 2%;
      padding: 35px 15px;
      @include box-shadow;
      @include transition(0.3s);
      // @include respond-to(tablet) {
      //   min-height: 370px;
      // }

      // @include respond-to(phone) {
      //   min-height: 315px;
      // }
    }
    &:nth-child(4) {
      page-break-after: always; /* CSS 2.1 syntax */
      break-after: always; /* CSS 3 syntax */
    }
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
    &.noPointing {
      cursor: auto;
    }
  }


  .aod-features-text-container {
    @include respond-to(laptop, desktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 250px;
    }
  }

  .aod-features-text-title {
    text-align: center;
    color: $Text;
    font-size: 19px;
    font-weight: bold;
    font-family: $font-family-serif-bold;
    line-height: 26px;
    margin-bottom: 3px;
    min-height: 50px;
    @include respond-to(phone, tablet) {
      text-align: center;
      font-size: 22px;
      max-width: 30em;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .aod-features-text-details {
    p {
      min-height: 45px;
      font-size: 15px;
      color: $Text;
      opacity: 0.8;
    }
  }
  .aod-features-cta-block {
    margin-top: auto;
    position: relative;
    height: 40px;
    a.explore-link{
      position: absolute;
      text-decoration: none;
      float: right;
      bottom: 0px;
      right: 0px;
    }
    .featured-services-button {
      margin-left: 16px;
    }
  }
  .aod-features-img-container {
    @include respond-to(laptop, desktop) {
      height: 250px;
    }
  }
}
.aod-ratings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1ch;
  margin-bottom: 10px;
  .aod-ratings-stars {
    width: 140px;
    height: 40px;
  }
  p {
    margin: 0;
  }
  a {
    color: $Text;
    font-weight: 700;
  }
  .aod-ratings-anchor {

  }
}
#pagination-container {
  .pager {
    li {
      margin-right: 10px;
      a {
        min-width: 40px;
        text-decoration: none;
      }
      &.active {
        a {
          background: $Accent200;
        }
      }
    }
  }
}
.editor_image {
  width: 80%;
  margin: 2% auto;
  display: block;
  @include border-radius-12;
}
.title-block {
  position: relative;
  h1.jumbo {
    position: relative;
  }
  .pen {
    padding-right: 7px;
    height: auto;
    padding-bottom: 25px;
    padding-top: 20px;
    background: url(../img/pen-highlight.svg) no-repeat;
    background-size: auto;
    background-size: 257px;
    @include respond-to(755px, tablet){
      background-size: 210px;
    }
    &.captions {
      background-size: 300px;
      padding-top: 21px;
    }
    &.transcript {
      background-size: 341px;
      padding-bottom: 25px;
      padding-top: 31px;
    }
    &.video-subtitles {
      padding-top: 13px;
    }
    &.purchase-subtitles {
      padding-top: 13px;
      background-size: 265px;
    }
    &.text-translation {
      padding-top: 13px;
      background-size: 231px;
    }
    @include respond-to(0, 755px){
      &.video-subtitles, &.captions, &.transcript, &.purchase-subtitles, &.text-translation {
        background-size: 0px;
      }
    }
  }
  .edition-title-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 320px;
    padding: 2em;
    .edition_title {
      font-size: 40px;
      font-family: $font-family-serif;
      text-align: left;
      .pen {
        padding-right: 12px;
        height: auto;
        padding-bottom: 13px;
        padding-top: 3px;
        padding-left: 16px;
        background-size: 154px;
      }
    }
    a {
      width: fit-content;
    }
  }
}

.aod-hero {
  padding-top: 3%;
  .bg-left {
    width: 45%;
    height: auto;
    position: absolute;
    z-index: -1;
    left: 0;
    top: -180px;
    opacity: 0.4;
    img {
      width: 100%;
    }
    @include respond-to(1024px, 1439px) {
      top: -176px;
    }
    @include respond-to(phone, tablet) {
      top: 70px;
    }
    @include respond-to(phone) {
      top: 10vh;
    }
  }
  .bg-right {
    width: 43%;
    height: auto;
    position: absolute;
    z-index: -1;
    right: 2vw;
    top: -180px;
    opacity: 0.4;
    img {
      width: 100%;
    }
    @include respond-to(1024px, 1439px) {
      top: -174px;
    }
    @include respond-to(phone, tablet) {
      top: 70px;
    }
    @include respond-to(phone) {
      top: 10vh;
    }
  }
  .service_order_hero {
    .card {
      width: 90%;
      border: 6px solid $Grey300;
      margin: 0 auto;
      max-width: 1300px;
      min-height: 295px;
      height: auto;
      overflow: hidden;
      background: #fff;
      border-radius: 12px;
      padding: 20px 25px;
      // @include box-shadow;
      @include transition(0.3s);
      @include respond-to(phone) {
        min-height: 315px;
      }
      .service-card {
        padding: 20px 25px;
        @include box-shadow;
        min-height: 300px;
        margin-bottom: 30px;
        margin-right: 45px;
      }
      a.back-top {
        float: left;
        font-weight: 400;
        margin-top: 1%;
        @media screen and(max-width: $screen-sm-min) {
          display: none;
        }
      }
      .aod-descrip-cta-column {
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-direction: column;
        justify-content: center;
        gap: 2ch;
        min-height: 260px;
        .logos-hero {
          width: 70%;
          margin: 0;
        }

      }
      h2.step-1-question {
        font-size: 24px;
        line-height: 34px;
        margin: 45px 0;
        text-align: center;
        font-weight: 400;
      }
      img.aod_services_image {
        width: 370px;
        margin-left: 15px;
        opacity: 0.8;
        margin: 0 auto;
        display: block;
        &.text-translation, &.text-transcript {
          width: 260px;
        }
        &.hero-all {
          width: 300px;
          margin-top: 5%;
        }
      }
      img.aod_services_illustration {
        width: 300px;
        margin-left: 30%;
        @media screen and(max-width: $screen-sm-min) {
          margin-left: 0;
        }
      }
      img.aod_ratings{
        display: block;
        margin: 0 auto;
        width: 200px;
        height: auto;

      }
      .cta {
        width: fit-content;
        font-size: 20px;
        border-radius: 36px;
      }
      .heroTextlinkContainer {
        display:flex;
        flex-direction: row;
        gap: 2ch;
        .heroTextlink {
          margin-top: 2%;
        }
      }
    }
    .aod-details-svg {
      width: 72px;
      height: 65px;
      @media screen and(max-width: $screen-sm-min) {
        width: 27px;
        height: 38px;
      }
    }
    .group-links {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: flex-end;
      align-content: flex-end;
      a {
        font-weight: 400;
        margin: 11px 3em;
        font-weight: 400;
      }
    }
  }

  .switch-field label {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-height: 290px;
    padding: 15px 1em;
    box-shadow: none;
    border: 4px solid $Grey300;
    @media screen and(min-width: $screen-md-min)  and(max-width: $screen-md-max) {
      min-height: 364px;
    }
    @media screen and(max-width: $screen-md-max) {
      padding: 1em;
    }
    @include respond-to(phone) {
      height: 275px;
      margin-top: 5%;
    }
    svg {
      left: auto;
      position: relative;
    }
    .aod-services-tag {
      padding: 1% 5%;
      font-size: 0.8em;
      border:1px solid $Accent300;
      @include border-radius-12;
    }
    .aod-services-text-title {
      float: left;
      text-align: left;
      width: 95%;
      font-size: 20px;
      font-family: $font-family-serif-bold;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 3px;
      margin-top: 5px;
      @include respond-to(phone, tablet) {
        width: 63%;
        }
    }
    .aod-services-details-text {
      font-size: 15px;
      text-align: left;
      color: $Text;
      opacity: 0.8;
    }
    p {
      font-size: 16px;
      text-align: center;
      padding: 0.3em;
    }
    .tile-tooltip {
      opacity: 0;
      display: none;
      font-size: 1.2em;
      text-align: left;
      sup {
        top: 0;
      }
      &.active {
        opacity: 1;
        display: block;
        @media screen and(max-width: $screen-sm-max) {
          display: none;
        }
      }
      @media screen and(max-width: $screen-sm-min) {
        .con-tooltip {
          .tooltip {
            width: 29vh;
          }
        }
      }
    }
    &:hover {
      .aod-features-svg {
        opacity: 1;
      }
    }
    .aod-services-details-links {
      display: flex;
      gap: 2ch;
      right: 5%;
      bottom: 7%;
      justify-content: flex-end;
      @media screen and(min-width: $screen-md-min)  and(max-width: $screen-md-max) {
        flex-direction: column;
        align-items: center;
        gap: 0.5ch;
      }
      .cta {
        font-size: 14px;
        padding: 0.8em 1em;
        @media screen and(max-width: $screen-sm-max) {
          font-size: 12px;
        }
      }
      .inner-cta {
        text-decoration: none;
        margin-top: 10px;
        @media screen and(min-width: $screen-md-min)  and(max-width: $screen-md-max) {
          svg {
            display: none;
          }
        }
      }
    }
    &:hover {
      border: 4px solid $Primary;
    }
  }
  #burnin_checkbox {
    margin-right: 30px;
    display: block;
  }
  .burnin {
  margin-left: 30px;
  padding: 3px 0 0 0;
  font-weight: 400;
  }
}
#ratingsAod {
  @include border-radius-24;
}

#how-it-works {
  position: relative;
  @media screen and(max-width: $screen-sm-max) {
    padding-top: 13%;
  }

  .arrow {
    width: 8%;
    margin: 0 auto;
    display: block;
  }
}
.title-underline {
  width: 225px;
  background: url(../img/title-underline.svg) no-repeat;
  background-size: cover;
  margin-bottom: 0.5em;
  height: 22px;
  margin-top: -12px;
  margin-left: -21px;
  &.align-left {
    margin: 0 0px 0 -20px;
  }
}
p.tag {
  text-transform:uppercase;
  letter-spacing: 0.2em;
  font-size:18px;
  line-height:28px;
  margin: 0 0 0 2px;
}

.higher {
  margin-top: -9vh;
  @include respond-to(tablet) {
    margin: 0px;
  }
  @include respond-to(phone) {
    margin: 0px;
  }
}

.hero-asr-card {
  border: 1px solid $Accent600;
  padding: 2em;
  @include border-radius-12;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    border: 1px solid $Primary;
  }
  .asr-btn-icon {
    width: 120px;
    height: 100px;
    margin-right: 5%;
    float: left;
    @include respond-to(phone) {
      width: 80px;
      height: 60px;
    }
  }
  h3, p {
    color: $Text;
    @media(max-width: 450px) {
      min-height: 100px;
    }
  }
  h3 {
    font-family: $font-family-serif-bold;
  }
}

.services_view {
  #get-started-services {
    .title-block {
      position: relative;
      h2.jumbo {
        position: relative;
        @include respond-to(tablet) {
          font-size: 52px;
          }
        @include respond-to(phone) {
        font-size: 48px;
        }
      }
      .pen {
        padding-right: 19px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 38px;
        background: url(../img/pen-highlight-2.svg) 13px 75px no-repeat;
        background-size: 335px;
        @include respond-to(755px, tablet){
          background-size: 339px;
          padding-top: 30px;
        }
        @include respond-to(0, 755px){
          background-size: 280px;
          padding-top: 31px;
        }
      }
    }
    legend {
      color: $Text;
      background-color: transparent;
    }
  }
}

.illustration-how-it-works-aod {
  &.upload svg {
    width: 180px;
  }
  &.subtitles svg {
    width: 200px;
  }
  &.delivery svg {
    width: 165px;
  }
  svg {
  display: block;
  width:140px;
  height: auto;
  margin: 0 auto;
  }
}
.illustration-how-it-works-aod-arrow-1 {
  position: absolute;
  left: 23%;
  top: -6vh;
  svg {
    width: 150px;
  }
  @include respond-to(tablet) {
    display: none;
  }
  @include respond-to(phone) {
    display: none;
  }
}
.illustration-how-it-works-aod-arrow-2 {
  position: absolute;
  margin-left: 37vw;
  margin-top: -6vh;
  svg {
    width: 150px;
  }
  @include respond-to(tablet) {
    display: none;
  }
  @include respond-to(phone) {
    display: none;
  }
}
.custom-shape-divider-top-1673992136, .custom-shape-divider-top-1663868153, .custom-shape-divider-top-1673446882 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1673992136 svg,
.custom-shape-divider-top-1663868153 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1673992136 .shape-fill, .custom-shape-divider-top-1663868153 .shape-fill, .custom-shape-divider-top-1673446882 .shape-fill  {
  fill: #FFFFFF;
}

#success-stories {
  img {
    max-width: 180px;
    padding: 1%;
    width: 100%;
    margin-top: 15px;
    &.figure-top {
      width: 150px;
      margin-right: -90%;
      border: 9px solid $Info100;
      border-radius: 50%;
      padding: 0;
      @include respond-to(phone) {
        display:none;
      }
    }
    &.figure-bottom {
      margin-right: -40%;
      margin-top: 20%;
      border: 9px solid $Error300;
      border-radius: 50%;
      padding: 0;
      @include respond-to(phone) {
        display:none;
      }
    }
    &.logos {
      width: 45%;
      max-width: 650px;
    }
  }

  p {
    &.tag {
      font-size: 16px;
    }
    &.success {
      margin-top: 10px;
      margin-bottom: 30px;
      padding: 0 11%;
      font-size: 1.3em;
      line-height: 1.5em;
    }
  }
  .success-svg {
    width: 100%;
    height: 270px;
  }
  .figure {
    font-family: $font-family-serif-bold;
    font-weight: 700;
    margin-top: 40px;
    font-size: 4em;
    height: 81px;
    padding: 21px 0;
    &.sub {
      background: url(../img/pen-highlight-1.svg) 77px 67px no-repeat;
      background-size: 151px;
    }
    &.mn {
      background: url(../img/pen-highlight-2.svg) 420px 59px no-repeat;
      background-size: 280px;
    }
  }
}

#feature-highlight {
  .container {
    width: 90vw;
    max-width: 1300px;
    @include border-radius-24;
    padding: 2% 5%;
    border: 6px solid $Grey300;
  }
  .feature-highlight-star {
    position: relative;
    span {
      position: absolute;
      right: -27px;
      top: 12px;
    }
  }
  p {
    @include respond-to(tablet) {
      text-align: center;
    }
    @include respond-to(phone) {
      text-align: center;
    }
  }
  .feature-highlight-img svg {
    display: block;
    margin: 2% auto 2% auto;
    @include respond-to(phone) {
      margin: 0 auto 0 auto;
    }
  }
  @include respond-to(phone, tablet) {
    text-align: center;
  }
}

.aod-features-img {
  .aod-features-svg {
    width: 90px;
    height: 100px;
    margin: 0 auto;
    display: block;
    opacity: 0.7;
    @include respond-to(phone, laptop) {
    height: 95px;
    }
  }
}
.meet-subtitler {
  width: 90vw;
  max-width: 1300px;
  .random-subtitler {
    display: none;
  }
  padding: 0% 0 3% 0;
  @include respond-to(phone, tablet) {
    padding: 10% 0;
  }
  .meet-subtitler-team {
    display: block;
    margin: 0 auto;
    width: 30%;
    max-width: 280px;
    height: auto;
  }
  .title-underline {
    margin: 0 auto;
  }
  .meet-subtitler-title {
    width: 100%;
    margin: 5% auto 0 auto;
    img {
      width: 50%;
      @include respond-to(tablet) {
        max-width: 61%;
        height: auto;
        min-height: 246px;
      }
      @include respond-to(phone) {
        max-width: 61%;
        height: auto;
        min-height: 115px;
      }
    }
  }
  p {
    font-size: 18px;
  }
  .meet-subtitler-img {
    width: 35%;
    height: auto;
    float: left;
    margin: 1% 2%;
  }
  .meet-subtitler-quote-block {
    margin-left: 27%;
    @include respond-to(phone, tablet) {
      margin-left: 0;
    }
    .meet-subtitler-quote {
      position: relative;
      padding: 4vh 16%;
      max-width: 90%;
      font-family: $font-family-serif;
      font-size: 28px;
      line-height: 36px;
      border: 2px solid $Warning300;
      border-top-right-radius: 48px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 10px;
      @include respond-to(phone, tablet) {
        max-width: 100%;
        font-size: 22px;
        line-height: 30px;
      }
      &:after {
          content:"";
          position: absolute;
          border: 2px solid $Warning300;
          border-radius: 0 50px 0 0;
          width: 60px;
          height: 60px;
          bottom: -60px;
          left: 50px;
          border-bottom: none;
          border-left: none;
          z-index: 3;
      }
      &:before {
          content:"";
          position: absolute;
          width: 80px;
          border: 6px solid #fff;
          bottom: -3px;
          left: 50px;
          z-index: 2;
      }
    }
    .quotation-mark {
      position: absolute;
      content: "”";
      color: $Warning300;
      font-size: 10rem;
      line-height: 0;
      bottom: -32px;
      right: 80px;
    }
    .meet-subtitler-details {
      margin-left: 127px;
      margin-top: 20px;
      @include respond-to(tablet) {
        margin-left: 12vw;
      }
      @include respond-to(mobile) {
        margin-left: 14vw;
      }
      .meet-subtitler-details-language {
        margin-top: 1%;
        display: inline-block;
        .flag {
          float: left;
          svg {
            width: 50px;
            height: 35px;
            opacity: 0.8;
          }
        }
        .text {
          float: left;
          p {
            padding-top: 4px;
            display: inline-block;
            &:before {
              content: "";
              display: inline-block;
              height: 16px;
              vertical-align: middle;
            }
          }
        }
      }
      .meet-subtitler-details-under {

        .meet-subtitler-details-location {
          float: left;
        }
        .meet-subtitler-details-figures {
          float: left;
          margin-left: 2%;
        }
        .meet-subtitler-details-joined {
        }
      }
    }
  }
}

#aod-languages {
  .aod-languages-title {
    @include respond-to(phone, tablet) {
      margin-top: 6vh;
    }
  }
  .aod-language-block {
    margin-top :1%;
    @include respond-to(phone, tablet) {
      margin-top: 3%;
    }
    min-height: 120px;
    a {
      text-decoration: none;
    }
    .flag {
      float: left;
      margin-top: -3px;
      .flag-svg {
        width: 40px;
        padding-right: 10%;
        height: 33px;
      }
    }
    h3 {
      font-family: $font-family-serif;
    }
    p {
      clear: both;
      font-size: 0.8em;
    }
  }
  .languages-collabsible {
    .lbl-toggle {
      display: block;
      font-size: 1.3rem;
      text-align: left;
      color: $Primary;
      background: none;
      cursor: pointer;
      border-radius: 7px;
      transition: all 0.25s ease-out;
      @include respond-to(phone, tablet) {
        text-align: center;
      }
    }
    .lbl-toggle::after {
      content: 'See more languages';
    }
    .toggle:checked + .lbl-toggle::after {
      content: 'Hide';
    }
    .toggle:checked+.lbl-toggle+.collapsible-content {
      overflow: visible;
    }
  }
}

.getStartedAODpurchase {
  .pickIt {
    span {
      color: $Warning400;
    }
  }
  .arrows {
    display: block;
    margin: 0 6%;
  }
  .aodBlockpurchase {
    margin-top: 3%;
    width: 100%;
    display: flex;
    gap: 3ch;
    margin-bottom: 1%;
    margin-left: -0.7%;
    @include respond-to(phone) {
      margin-left: 0;
      flex-direction: column;
    }
    .heroCaptions, .expertSubtitles {
      width: 50%;
      @include border-radius-12;
      @include respond-to(phone) {
        width: 100%;
      }
      img {
        width: 100%;
        max-width: 160px;
      }
      &:hover{
        opacity: 0.9;
      }
      .cta {
        font-size: 18px;
      }
      .inner-cta {
        margin-top: 4%;
        text-decoration: none;
      }
      h2 {
        font-family: $font-family-serif-bold;
        color: $Text;
      }
      h3 {
        font-size: 16px;
      }
      .tag {
        font-size: 15px;
        margin-bottom: 3%;
      }

    }
    .heroCaptions {
      border: 6px solid $Primary050;
      padding: 1.5em;

    }
    .expertSubtitles {
      border: 6px solid $Error100;
      padding: 1.5em;

    }
  }
}
#get-started-services {
  padding: 5% 0;
  h2.jumbo {
    @include respond-to(phone) {
      font-size: 32px;
    }
  }
  h3 {
    font-size: 24px;
  }
  .fieldOptional {
    display: none;
  }
}

// Media Queries

@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {
  #get-started-services {
    iframe {
      min-height: 740px;
    }
  }
}

@media(max-width: $screen-xs-max) {

}

.aod-page-intro-text-row {
  @include respond-to(phone, tablet) {
    margin-bottom: 50px;
  }

  @include respond-to(desktop) {
    margin-bottom: 50px;
  }
}
.aod-languages-title {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 1px;
  @include respond-to(phone, tablet) {
    font-size: 34px;
    text-align: left;
  }
  span {
    color: $Warning400;
  }
}

.aod-page-how-it-works-title, .meet-subtitler-title, .aod-faq-title {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 1px;

  @include respond-to(phone, tablet) {
    font-size: 34px;
    text-align: center;
  }
  span {
    color: $Warning400;
  }
}
p.aod-title-under {
  font-size: 18px;
}

.aod-page-how-it-works-container {
  width: 90vw;
  max-width: 1300px;
  margin: 0 auto;
  .aod-page-how-it-works-animation {
    .bg-step1, .bg-step2, .bg-step3 {
      position: relative;
      display: none;
      height: 571px;
      @include respond-to(phone, tablet) {
        height: 290px;
      }
      &.visible {
        display: block;
      }
      .hidding-brand {
        position: absolute;
        background: #fff;
        height: 30px;
        width: 165px;
        right: 0;
        @include respond-to(phone) {
          height: 22px;
          width: 68px;
        }
      }
    }
    .bg-step1 {
      background: url(../img/aod-hiw-sub-1.svg) no-repeat;
      background-size: 100% 340px;
      background-position-x: 60px;
      @include respond-to(phone, tablet) {
        background-size: 100% 190px;
        background-position-x: 20%;
      }
    }
    .bg-step2 {
      background: url(../img/aod-hiw-sub-2.svg) no-repeat;
      background-size: 80% 250px;
      background-position-x: 145px;
      @include respond-to(phone, tablet) {
        background-size: 80% 160px;
        background-position-x: 46%;
      }
    }
    .bg-step3 {
      background: url(../img/aod-hiw-del-3.svg) no-repeat;
      background-size: 104% 260px;
      @include respond-to(phone, tablet) {
        background-size: 104% 200px;
      }
    }

    video {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      height: 400px;
      padding-left: 8vw;
      @include respond-to(phone, tablet) {
        padding-left: 0;
        height: 280px;
      }
      &.play {
        display: block;
      }
    }
  }
  .aod-page-how-it-works-text {
    .aod-page-how-it-works-text-block {
      padding: 3% 7%;
      margin-top: 1%;
      background-color: transparent;
      @include border-radius-24;
      @include transition(0.5s);
      cursor: pointer;
      &:hover {
        background-color: $Grey200;
      }
      &.active {
        @include info-blue-gradient;
      }
    }
    .aod-page-how-it-works-text-cta {
      text-align:center;
    }
  }

}

.aod-page-how-it-works-content {
  position: relative;
  margin-top: 11vh;
  text-align: center;
  @include respond-to(laptop, desktop) {
    display: flex;
    justify-content: center;
  }
  @include respond-to(phone) {
    margin-top: 2vh;
  }
}

.aod-page-how-it-works-header {
  @include respond-to(phone) {
    .aod-page-how-it-works-animation {
      video {
        padding-left: 0;

      }
    }
  }

  @include respond-to(tablet, desktop) {
    height: 2.5em;
  }
}

.aod-page-how-it-works-container {
  @include respond-to(phone){
    padding-left: 8%;
  }
  h3 {
    color: $Warning400;
  }
}
.ratings-block {
  padding: 2em 5em;
  text-align: left;
  min-height: 550px;
  h2 {
    font-family: $font-family-serif-bold;
    font-size: 30px;
  }
  p.descrip {
    font-size: 24px;
    line-height: 32px;
    margin: 1% 0 2% 0;
  }
  .logos-cta{
    width: 50%;
    margin: 1em auto;
  }
  .ratings-block-average, .ratings-block-user {
    @include border-radius-12;
    border: 4px solid $Accent200;
    padding: 1em;
    background-color: #fff;
    .average-rating-svg {
      width: 120px;
      height: 40px;
    }
  }
  .ratings-block-average {
    margin-top: 7px;
    .name {
      font-family:$font-family-serif-bold;
    }
  }

  .ratings-block-user {
    margin-top: 1%;
    .name {
      font-family:$font-family-serif-bold;
    }
    .date {
      font-size: 0.8em;
    }
  }
}

.cta-block {
  padding: 3em 0;
  text-align: center;
  h2 {
    font-family: $font-family-serif-bold;
    font-size: 30px;
  }
  p.descrip {
    font-size: 24px;
    line-height: 32px;
    margin: 1% 0 2% 0;
  }
  .logos-cta{
    width: 50%;
    margin: 1em auto;
  }
}

.aod-page-services-container {
  width: 90vw;
  max-width: 1300px;
  margin: 2em auto;
  .aod-page-services-animation {
    .bg-aodService1, .bg-aodService2, .bg-aodService3, .bg-aodService4, .bg-aodService5 {
      display: none;
      height: 571px;
      margin-top: 5%;
      a {
        display: block;
        margin: 0 auto;
        width: fit-content;
      }
      h3 {
        font-family: $font-family-serif-bold;
        font-size: 38px;
        line-height: 48px;
        text-align:center;
        @include respond-to(phone, tablet) {
          text-align:left;
        }
      }
      p {
        font-size: 24px;
        line-height: 30px;
        text-align:center;
        @include respond-to(phone, tablet) {
          text-align:left;
        }
      }
      @include respond-to(phone, tablet) {
        height: 290px;
        margin-left: 0;
      }
      &.visible {
        display: block;
      }
      img {
        display: block;
        width: 250px;
        margin: 0 auto;
        margin-bottom: 2%;
      }
    }
    @include respond-to(phone) {
      display: none;
    }
  }
  .aod-page-services-text {
    .aod-page-services-text-block {
      padding: 6% 7%;
      margin-top: 1%;
      background-color: transparent;
      @include border-radius-24;
      @include transition(0.3s);
      cursor: pointer;
      border-top: 1px solid $Primary;
      h2 {
        font-family: $font-family-serif-bold;
      }
      a {
        float:right;
        text-decoration: none;
      }
      &:hover {
        background-color: $Grey200;
      }
      &.active {
        border-top: 4px solid $Primary;
        margin-top: -5px;
        h2, p {
          color: $Primary;
        }
        a {

        }
      }
    }
    .aod-page-services-text-cta {
      text-align:center;
    }
  }

}

.aod-page-how-it-works-content {
  position: relative;
  margin-top: 11vh;
  text-align: center;
  @include respond-to(laptop, desktop) {
    display: flex;
    justify-content: center;
  }
  @include respond-to(phone) {
    margin-top: 2vh;
  }
}

.aod-page-how-it-works-header {
  @include respond-to(phone) {
    .aod-page-how-it-works-animation {
      video {
        padding-left: 0;

      }
    }
  }

  @include respond-to(tablet, desktop) {
    height: 2.5em;
  }
}

.aod-page-how-it-works-container {
  @include respond-to(phone){
    padding-left: 8%;
  }
  h3 {
    color: $Warning400;
  }
}

.blog-entries-block {
  h2 {
    font-family: $font-family-serif-bold;
    font-size: 24px;
  }
  .entry-card {
    @include box-shadow;
    display: -webkit-box;
    display: flex;
    border:none;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    background: #fefefe;
    overflow: hidden;
    color: $Text;
    text-decoration: none;
    .entry-card-image {
      position: relative;
    }

    .entry-label.alert{
      background: $Warning400;
      color: $Warning900;
      letter-spacing: 0.1em;
    }

    .entry-card-tag{
      top: 16px;
      top: 1rem;
      left: 16px;
      left: 1rem;
      text-transform: uppercase;
      font-family:$font-family-sans-serif;
      border-radius: 3%;
    }
    .entry-card-section {
      padding: 1rem;
      .article-title {
        font-family: $font-family-serif-bold;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 0;
      }
      .article-summary {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
      .article-link{
        position: absolute;
        bottom: 20px;
        color: $Primary;
      }
    }
    .entry-label {
      display: inline-block;
      padding: .33333rem .5rem;
      border-radius: 3px;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: default;
      background: #d74998;
      color: #fefefe;
    }
    &:hover {
      opacity: 0.8;
      text-decoration: none;
      box-shadow:
      0 1.1px 1.1px rgba(0, 0, 0, 0.012),
      0 2.5px 2.7px rgba(0, 0, 0, 0.017),
      0 4.8px 5px rgba(0, 0, 0, 0.02),
      0 8.5px 8.9px rgba(0, 0, 0, 0.024),
      0 15.9px 16.7px rgba(0, 0, 0, 0.029),
      0 38px 40px rgba(0, 0, 0, 0.04);
    }
  }

  .guides {
  min-height: 320px;
  }
}

#amara-difference {
  .container {
    margin: 3% auto;
    padding: 5%;
    width: 90%;
    @include border-radius-12;
    background: rgb(250,224,250);
    background: linear-gradient(61deg, rgba(250,224,250,1) 0%, rgba(199,249,237,1) 58%, rgba(215,255,247,1) 100%);
    h2 {
      font-family: $font-family-serif-bold;
      font-size: 48px;
      @include respond-to(phone){
        font-size: 18px;
      }
    }
    h3 {
      font-size: 24px;
      @include respond-to(phone){
        font-size: 12px;
      }
    }
    .pcf-tie {
      display: block;
      max-width: 200px;
      margin: 2.5em auto 1em auto;
    }
    .collaboratorsLogotitle {
      text-align: center;
      font-family: $font-family-serif-bold;
    }
    .collaboratorsLogo {
      display: flex;
      justify-content:space-around;
      align-items: center;
      margin: 1% 2%;
      gap: 1.5ch;
    }
  }
}

#aod-faq {
  padding-top: 7%;
  .title-underline {
    margin: 0 auto;
    &.align-left {
      margin: 0 0px 0 -20px;
    }
  }
  @include respond-to(phone){
    padding-top: 17%;
  }
  // .accordion {
  //   width: 100%;
  // }
  .question-footer-team {
    width:20%;
  }
}

.aod-testimonials {
  .row{
    .clientTestimonialtext {
      .jumbo {
        font-size: 52px;
        @include respond-to(phone){
          font-size: 22px;
        }
      }
      .tag {
        color: $Warning900;
        background: $Warning400;
        width: fit-content;
        margin: 0 auto 2em auto;
        padding: 0.2em 1em;
        border-radius: 24px;
      }
    }
    .circle-images{
        all: unset;
        display: flex;
        flex-direction: column;
        div.images{
          figure{
              img{
                  margin-right:auto;
                  max-width: 300px;
                  width: 100%;
                  margin-top: -50px;
              }
          }
        }
      }
    }
    .carousel{
        .carousel-indicators{
            all: unset;
            padding-top: 43px;
            display: flex;
            list-style: none;
            li{
                background: #000;
                background-clip: padding-box;
                height: 2px;
            }
        }
        .carousel-inner{
          .carousel-item{
            .quote-wrapper {
                position: relative;
                margin: 0.5em auto;
                padding: 4vh 16%;
                max-width: 78%;
                font-family: $font-family-serif;
                font-size: 28px;
                line-height: 36px;
                border: 8px solid $Warning300;
                background-color: #fff;
                border-top-right-radius: 48px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 10px;
                @include respond-to(phone, tablet) {
                  max-width: 100%;
                  font-size: 22px;
                  line-height: 30px;
                }

                p{
                  font-size: 24px;
                  line-height: 1.72222;
                  font-weight: 500;
                }
                h3{
                  font-family: $font-family-serif-bold;
                  font-weight: 700;
                  margin-top: 37px;
                  font-size: 20px;
                  line-height: 1.45;
                }
                .quotation-mark {
                  position: absolute;
                  content: "”";
                  color: $Warning300;
                  font-size: 10rem;
                  line-height: 0;
                  top: 80px;
                  right: 80px;
                }
            }
          }
        }
    }
}
@media only screen and (max-width: 1200px) {
  .aod-testimonials{
      .row{
          .circle-images{
              margin-right: 25px;
          }
      }
  }
}
