.paymentForm-card {
  margin-bottom: 4px;
  width: 100%;
  padding: 8px 10px;
  margin: 0;
  border: 1px solid light($gray);
  background-color: #fff;
}

.paymentForm-cardErrors {
  color: $state-danger-text;
  font-size: $font-size-tiny;
  min-height: 22px;

  &.disabled {
    color: dark($gray);
  }
}

.paymentPane {
  text-align: center;
  margin: 0 auto;
}

.paymentTotal-summaryContainer {
  @extend .clearfix;
  border-top: 1px solid $snow;
  margin-bottom: 40px;
  padding-top: 4px;
}

.paymentTotal-summary {
  float: right;
  table-layout: fixed;

  td {
    text-align: right;
    padding-left: 12px;
  }
}

.paymentTotal-discount {
  color: $amaranth;
}

.paymentPane-loading {
  color: $plum;
}

.paymentPane-spinner {
  margin-top: 20px;
  font-size: 40px;
}

.paymentPane-form {
  display: none;
}
