.breadcrumb-onboarding {
  display: flex;
  padding-top: 3rem;
  margin-top: 7rem;
  border-top: 1px solid $Accent200;
  margin-bottom: 2rem;
  #footer-logo {
    width: 150px;
    margin-bottom: 0px;
  }
  .item {
    display: flex;
    align-items: center;
    min-height: auto;

    &:not(:first-child) {
      margin-left: 3rem;
    }
    &.multiStepPane-goto {
      font-weight:500;
      text-decoration: none;
      color: $Grey900;
      &:hover {
          opacity: 0.7;
          .text {
              text-decoration: underline;
          }
      }
    }
    &.item--active .icon {
      background-color: $Info500;
      color: #fff;
    }

    &.item--active .text {
      color: $Info500;
    }
  }


  .icon {
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    background-color: $Accent100;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.aod-forms {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .item {
      .text {
        line-height: 22px;
        font-size: 16px;
        span {
          display: inline-block;
        }
        .text-mobile-hidden {
          display: inline-block;
        }
        .text-mobile-visible {

        }
      }
    }
  }
}

@media screen and(max-width: $screen-xs-max) {
  .breadcrumb-onboarding {
    &.aod-forms {
      .icon {
        width: 3rem;
        height: 3rem;
      }
      justify-content: space-between;
      .item {
        min-height: 40px;
        .text {
          padding-right: 20px;
          font-size: 13px;
          line-height: 19px;
          &:first-letter {
            text-transform: uppercase;
          }
          span.text-mobile-hidden {
            display: none;
          }
          span.text-mobile-visible {
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
        &.item--active {
          background-color: #fff;
          span.text-mobile-visible  {
            color: $Info;
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}


@media screen and(max-width: $screen-sm-min) {
  .breadcrumb-onboarding {
    flex-direction: column;
    margin-bottom: 3rem;
    padding-bottom: 1rem;

    .item {
      margin-bottom: 1rem;
      margin-left: 0;
      &:not(:first-child) {
        margin-left: 0rem;
      }

      &.item--active {
        border-radius: 5px;
        background-color: $Info500;
        .text {
          color: #fff;
        }
      }
    }
    &.aod-forms {
      align-items: normal;
      .item {
        min-height: 40px;
      }
    }
  }
}