
.tableSelect {
  tbody {
    max-height: 200px;
    overflow-y: auto;
    display: block;

    tr:hover {
      background: $snow;
    }

    td {
      @include ellipsis;
      cursor: pointer;
    }
  }
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

}

.tableSelect-checkboxColumn {
  width: 40px;

  .checkbox-icon {
    margin: 0;
  }
}


// Customizations for individual table selects

.tableSelect.styleguide {
  @include respond-to(phone) {
    .tableSelect-col3 {
      display: none;
    }
  }
}

.tableSelect.video-picker {
  @include respond-to(phone) {
    .tableSelect-col3 {
      display: none;
    }
  }
}
