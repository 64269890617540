@font-face {
    font-family: 'Peachi Regular';
    src: url('../fonts/peachi-regular-webfont.woff2') format('woff2'),
         url('../fonts/peachi-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Peachi Medium';
    src: url('../fonts/peachi-medium-webfont.woff2') format('woff2'),
         url('../fonts/peachi-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Peachi Bold';
    src: url('../fonts/peachi-bold-webfont.woff2') format('woff2'),
         url('../fonts/peachi-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Peachi Ultra';
    src: url('../fonts/peachi-black-webfont.woff2') format('woff2'),
         url('../fonts/peachi-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/DMSans-Regular.woff') format('woff');
    }

@font-face {
    font-family: 'DM Sans Italic';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/DMSans-Italic.woff') format('woff');
    }

@font-face {
    font-family: 'DM Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/DMSans-Medium.woff') format('woff');
    }

@font-face {
    font-family: 'DM Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/DMSans-Bold.woff') format('woff');
    }
