.contactUs {
    .jumbo {
        font-size: 56px;
    }
    textarea {
        resize: none;
        overflow: auto;
    }
    .tooltip {
        text-align: right;
    }

}
