.tabs {
  display: flex;
  margin: 0 -10px 20px;
  > * {
    padding: 10px 0;
    margin: 0 10px;
    color: $plum;
    text-decoration: none;
    white-space: nowrap;
    @include font-weight-normal;

    border-bottom: 2px solid transparent;
    &.active {
      border-bottom: 2px solid $plum;
    }

    &:hover, &:active, &:visited, &:focus {
      color: $plum;
      text-decoration: none;
    }
  }
}

// The menuButton is used to show/hide a menu with overflow tabs
.tabs-menuButtonContainer {
  // we want the button always at the right end of the tabs
  flex-grow: 1;
  text-align: right;
}
.tabs-menuButton {
  padding: 10px 4px;
  margin: 0 10px;

  color: $plum;
  background: inherit;
  border: 0;

  &:hover, &:active, &:visited, &:focus {
    color: $plum;
    background: inherit;
    border: 0;
  }
}

.tabs.vertical {
  flex-direction: column;
  border-bottom: none;
  > a {
    border-bottom: none;
    border-left: 4px solid light($gray);
    padding: 16px 40px 16px 12px;
    width: 300px;

    &.active {
      border-left: 4px solid $plum;
    }
    &:hover {
      border-left: 4px solid $plum;
      background: $snow;
    }
  }
}

.tabs.mini {
  border: 2px solid $Grey400;
  display: inline-flex;
  margin: 0 0 0 16px;
  border-radius: 4px;
  height: 45px;
  &.no-margin {
    margin: 0 0 0 5px;
  }

  > a {
    border: none;
    margin: 0;
    padding: 13px 10px;
    font-size: 14px;
    line-height: 16px;
    color: light($gray);
    &:hover{
      color:$Primary;
    }
    &.active {
      color: $white;
      background: $Grey600;
    }
  }
}

.tab {
  display: none;

  &.active {
    display: block;
  }
}

.verticalTabContainer {
  display: flex;
}

.verticalTabContainer-content {
  padding: 12px;
}

// Old-style tabs
ul.nav-tabs {
  list-style: none;
  > li {
    position: relative;
    float: left;
    margin: 0 10px;
    list-style: none;
    &:first-child {
      margin-left: 0;
    }
    > a {
      @include no-underline;
      padding: 10px 0;
      border: none;
      border-bottom: 3px solid transparent;
      @include font-weight-bold;
      color: $Grey100;
      background-color: transparent;
      &:hover, &:focus {
        color: $Primary;
        background-color: transparent;
        border-bottom: 3px solid transparent;
      }
    }
    &.active {
      > a {
        border: none;
        border-bottom: 3px solid $Primary;
        color: $Text;
        background-color: transparent;
        &:hover, &:focus, &:active {
          color: $Text;
          border: none;
          border-bottom: 3px solid $Primary;
          background-color: transparent;
        }
      }
    }
  }
  &.vertical {
    border-bottom: none;
  }
}

.tab-pane {
  position: relative;
  padding: 15px 0;
  .tab-actions {
    text-align: right;
    margin-bottom: 10px;
    .switch {
      position: relative;
      top: 8px;
      margin-right: 20px;
    }
    form {
      display: inline-block;
    }
  }
  .tab-content {

  }
}

ul.nav-tabs.vertical {
  > li {
    float: none;
    font-size: $font-size-normal;
    line-height: 24px;
    margin: 0;
    padding: 4px 0 4px 12px;
    border-left: 4px solid light($gray);

    > a {
      border-bottom: none;
    }

    &.active {
      border-left: 4px solid $plum;

      > a {
        border-bottom: none;
      }
    }

    &:hover {
      border-left: 4px solid $plum;
      background: $snow;

      > a {
        border-bottom: none;
      }
    }

  }
}

ul.nav-tabs.marketing {
  border: none;
  display: flex;
  justify-content: center;
  > li {
    float: none;
    margin: 0 40px;
    &.active {
      > a {
        color: #fff;
        border: none;
        background-color: transparent;
        &:hover, &:focus {
          cursor: default;
          pointer-events: none;
          color: #fff;
          border: none;
          background-color: transparent;
        }
      }
    }
    a {
      @include font-weight-bold;
      padding: 10px 50px;
      border: none;
      border-radius: 0;
      color: #fff;
      background-color: transparent;
      &:hover, &:focus {
        cursor: pointer;
        pointer-events: all;
        color: #fff;
        background-color: transparent;
      }
    }
  }
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {
  .nav-tabs.marketing {
    li {
      margin: 0 20px;
      a {
        padding: 10px 35px;
      }
    }
  }
}
