@font-face {
  font-family: 'amara';
  src:  url('../fonts/amara.eot');
  src:  url('../fonts/amara.eot#iefix') format('embedded-opentype'),
    url('../fonts/amara.ttf') format('truetype'),
    url('../fonts/amara.woff') format('woff'),
    url('../fonts/amara.svg#amara') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
i.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'amara' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


///////////////////////////


.icon-close:before {
  content: "\e90a";
}
.icon-check-circle:before {
  content: "\e915";
}

.icon-x-circle:before {
  content: "\e914";
}

.icon-info:before {
  content: "\e912";
}
.icon-alert:before {
  content: "\e913";
}
.icon-incomplete:before {
  content: "\e911";
}
.icon-initial-work:before {
  content: "\e90f";
}
.icon-avatar-team:before {
  content: "\e90d";
}
.icon-avatar-default:before {
  content: "\e90e";
}
.icon-complete:before {
  content: "\e90c";
}
.icon-evaluate:before {
  content: "\e90b";
}
.icon-approve_outline:before {
  content: "\e909";
}
.icon-deny:before {
  content: "\e90a";
}
.icon-trash:before {
  content: "\e910";
}
.icon-approve:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-clock:before {
  content: "\e902";
}
.icon-mail:before {
  content: "\e903";
}
.icon-review:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-star:before {
  content: "\e906";
}
.icon-transcribe:before {
  content: "\e907";
}
.icon-translate:before {
  content: "\e908";
}


///////////////////////////
// Using the same icon in a different context.
// These may need to be removed or refactored at some point to avoid confusion

.icon-loading:before {
  // TODO: replace this with a new, animated, loading icon
  @extend .icon-clock:before;
}

.icon-provider-request:before {
// This is still to be implemented.  Let's use the transcribe icon as a
// placeholder
  @extend .icon-transcribe:before;
}

.icon-forward:before {
  @extend .icon-arrow:before;
}
.icon-back:before {
  @extend .icon-arrow:before;
  display: inline-block;
  transform: rotate(180deg);
}
.icon-up:before {
  @extend .icon-arrow:before;
  display: inline-block;
  transform: rotate(-90deg);
}
.icon-down:before {
  @extend .icon-arrow:before;
  display: inline-block;
  transform: rotate(90deg);
}

.icon-check:before {
  @extend .icon-approve:before;
}

.icon-edit:before {
  @extend .icon-transcribe:before;
}

.icon-subtitles:before {
  @extend .icon-transcribe:before;
}

// sizes
.icon-sm {
  font-size: 25px;
}

// "small-to-medium" in-between size
.icon-sm-to-md {
  font-size: 33.33px;
}

.icon-md {
  font-size: 41.67px
}

.icon-lg {
  font-size: 83.33px;
}
