.searchbar {
  position: relative;
  input {
    padding-right: 32px;
  }

  &:after {
    font-family: 'amara' !important;
    @extend .icon-search:before;
    display: block;
    position: absolute;
    right: 16px;
    top: 3px;
    speak: never;
    text-transform: none;
    font-size: 22px;
    line-height: 22px;
    padding-top: 7px;
    color: $gray;
  }
  &:hover::after {
    cursor: pointer;
    opacity: 0.8;
  }
}
