.alert {
  padding: 15px;
  margin-bottom: 12px;
  border: 1px solid transparent;
  @include border-radius-12;
  overflow-wrap: break-word;
  &.alert-success {
    background-image: none;
    background-color: $state-success-bg;
    border-color: $state-success-border;
    color: $state-success-text;
  }
  &.alert-info {
    background-image: none;
    background-color: $state-info-bg;
    border-color: $state-info-border;
    color: $state-info-text;
  }
  &.alert-warning {
    background-image: none;
    background-color: $Warning100;
    border-color: $Warning300;
    color: $Warning900;
  }
  &.alert-error, &.alert-danger {
    background-image: none;
    background-color: $state-danger-bg;
    border-color: $state-danger-border;
    color: $state-danger-text;
  }
  &.alert-dismissible {
    .close {
      right: 0;
    }
  }
  p {
    // Undo bootstrap undoing our margin
    margin-bottom: 10px;
  }
}

.announcement {
  display: block;
  padding: 20px 30px;
  margin: 0;
  background-color: $brand-primary;
  color: #ffffff;
  text-align: center;
  a {
    color: $cream;
  }
}

// New messages alert
.newMessages {
  padding-top: 12px;
}

.newMessages-hide {
  @include no-underline;
  margin-top: -8px;
  padding: 8px;
}
