body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-sans-serif;
  line-height: $line-height-normal;
  font-size: $font-size-normal;
  color: $text-color;
}

h1, h2 {
  font-family: $first-level-headings-font-family;
  @include font-weight-normal;
  color: inherit;
  line-height: 1.25;
  margin: 0;
  font-weight: 700;
  .number {
    font-family: $font-family-sans-serif;
  }
  &.sans {
    font-family: $font-family-sans-serif;
  }
  &.marketing {
    font-family: 'Peachi Bold', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    font-weight: 500;
  }
}

h3, h4, h5, h6 {
  font-family: $second-level-headings-font-family;
  @include font-weight-normal;
  color: inherit;
  line-height: 1.25;
  margin: 0;
  .number {
    font-family: $font-family-sans-serif;
  }
  &.sans {
    font-family: $font-family-sans-serif;
  }
}
h1, .h1 {
  font-size: $font-size-h1;
  margin-bottom: 8px;
  @include header-colors;
}

h2, .h2 {
  font-size: $font-size-h2;
  margin-bottom: 8px;
  @include header-colors;
}

h3, .h3 {
  font-size: $font-size-h3;
  margin-bottom: 4px;
  @include header-colors;
}

h4, .h4 {
  font-size: 16px;
  @include header-colors;
}

h4, h5, h6 {
  font-family: $font-family-sans-serif;
}

.lined-header {
  border-bottom: 1px solid $gray;
  margin-bottom: 12px;
}

.mega {
  font-size: $font-size-mega;
  margin-bottom: 18px;
}

.jumbo {
  font-family:$font-family-serif;
  font-weight: 700;
  font-size: $font-size-jumbo;
  margin-bottom: 12px;
}

.text-normal {
  font-family: $font-family-sans-serif;
  font-size: $font-size-normal;
  @include font-weight-normal;
}

// .text-small is the preferred CSS class, but we also support the deprecated .small version

small, .small, .text-small {
  font-size: $font-size-small;
}

.tiny, .text-tiny {
  font-size: $font-size-tiny;
  @include font-weight-light;
}

.text-large {
  font-size: $font-size-h3;
}

.bold {
  @include font-weight-bold;
}

em {

}

.lead {
  font-family: $headings-font-family;
  font-size: 24px;
}


// Text Utilities

.text-nocase { text-transform: none !important; }
.text-capitalize { text-transform: capitalize !important; }
.text-lowercase { text-transform: lowercase !important; }
.text-uppercase { text-transform: uppercase !important; }

.italic {
  font-style: italic;
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {

  h1, .h1 { font-size: 35px; }
  h2, .h2 { font-size: 20px; }
  h3, .h3 { font-size: 18px; }
  h4, .h4 { font-size: 14px; }

  .lead {
    font-size: 18px;
  }
}

pre {
  overflow: auto;
  font-family: mono;
  white-space: pre;

  &.box {
    width: 100%;
    height: 200px;
  }
}

.ellipsis {
  @include ellipsis;
  max-width: 100%;
}

.break-all {
  word-break: break-all;
}
