
.illustration-section {
  position: relative;
}

.illustration {
  position: absolute;
  display: flex;
  justify-content:  center;
  align-items: center;
  top: 0; bottom: 0;
  left: 0; right: auto;
  height: 100%;
  width: 60%;
  overflow: hidden;
  pointer-events: none;
  &.logo-animation {
    width: 50%;
    overflow: visible;
  }
  &.illustration-left {
    left: 0;
    right: auto;
  }
  &.illustration-right {
    right: 0;
    left: auto;
  }
  &.illustration-full {
    width: 100%;
    left: 0;
    right: auto;
  }
  &.illustration-bottom {
    top: auto;
    bottom: 0;
    align-items: flex-end;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    z-index: 0;
  }
  svg {
    position: absolute;
    display: table;
    vertical-align: middle;
    height: 100%;
    max-width: 100%;
    height: auto;
    z-index: 0;
  }
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {
  .illustration {
    position: relative;
    width: 100%;
    &.logo-animation {
      display: none;
    }
    &.illustration-full {
      width: 100%;
      left: 0;
      right: auto;
      // top: -100px;
      // margin-bottom: -150px;

      top: -30px;
      margin-bottom: -130px;
      padding-bottom: 50px;
    }
    // &.illustration-bottom {
    //   position: absolute;
    //   margin-bottom: 0;
    //   top: 0;
    // }
  }
}

@media(max-width: $screen-xs-max) {

}
