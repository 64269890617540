
.faqsAccordionContainer {
  display: flex;
  justify-content: center;
}

.accordion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 57em;
  &.aod-accordion {
    width: 100%;
  }
}


.faqsAccordionQuestion {
  padding-right: 40px !important;
}

.faqParagraph {
  max-width: 50em;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: justify;
  font-size:18px;
  line-height:26px;
  @media(max-width: $screen-sm-max) {
    font-size:16px;
    line-height: 24px;
  }
}



.accordion-title {
  position: relative;
  color: $Text;
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  position: relative;
  display: block;
  padding: 10px 25px;
  margin: 0;
  cursor: pointer;
  &:hover{
    opacity:0.8;
  }
  &:after,
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 2px;
      background: #5a2747;
      top: 50%;
      left: 0px;
      transition: all 0.4s;
    }
    &:before {
      .accordion-section.expanded ~ & {
        transition: all 0.4s;
        transform: rotate(90deg);
        opacity: 0;
      }
    }
    &:after {
      transform: rotate(90deg);
      transition: all 0.3s;
      .accordion-section.expanded ~ & {
        transform: rotate(180deg);
      }
    }

  &.errors {
    background: $amaranth;
  }

  &.accordion-modal {
    background: $white;
    color: $charcoal;
    display: flex;
    justify-content: space-between;
  }
  &.cookie-consent-details-accordion-title {
    &:after,
    &:before {
      top: 57%;
    }
  }
}

.accordion-section {
  margin: auto auto 12px 0;
  padding: 1em;
  &.full-width {
    max-width: 70%;
    min-width: 40%;
  }
  &.expanded {
    background: rgb(250,224,250);
    background: linear-gradient(300deg, rgba(250,224,250,1) 0%, rgb(251, 245, 201) 58%);
    @include border-radius-12;
    .accordion-title {
      color: $Primary;
    }
    .accordion-title:before {
      transition: all 0.4s;
        transform: rotate(90deg);
        opacity: 0;
    }
    .accordion-title:after {
      background: $Primary;
      transform: rotate(180deg);
    }
  }
}


.accordion-caret {
  position: absolute;
  right: 16px;
  top: 12px;

  @include fa-icon;
  @extend .fas;
  font-size: 18px;
  font-family: $font-family-serif-bold;
  font-weight: 900; // needed to make fa-solid work

  &:before {
    content: fa-content($fa-var-angle-up);

    .accordion-section.expanded & {
      content: fa-content($fa-var-angle-down);
    }
  }
}

.accordion-content {
  display: none;
  clear: both;

  .accordion-section.expanded & {
    display: block;
    clear: both;
  }
}

@media(max-width: $screen-sm-max) {
  .faqsAccordion {
    width: 80vw;
  }
}