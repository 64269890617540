.home_view {}


// Media Queries

@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {

}

section#meet-amara {
  h2.jumbo {
    font-size: 50px;
    line-height: 60px;
    font-family: 'Peachi Ultra', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    font-weight:800;
    text-align:center;
  }
  @include respond-to(laptop, desktop) {
    h2.jumbo {
    font-size:82px;
    line-height: 92px;
    }
    margin-bottom: 100px;
  }
}

.home-page-full-width-section {
  @include respond-to(laptop, desktop) {
    max-width: none;
  }
}

.home-page-full-width-container {
  @include respond-to(laptop, desktop) {
    width: 100%;
  }
}

.home-page-intro-spacer {
  @include respond-to(phone, tablet) {
    height: 50px;
  }
}

.home-page-intro-top {
  margin-bottom: 50px;
  @include respond-to(tablet) {
    margin-top: 25px;
  }
  @include respond-to(laptop, desktop) {
    margin-top: 50px;
  }
}

.home-page-title-container {
  margin-bottom: 50px;
}

.home-page-intro-bottom {
  margin-bottom: 25px;
}

.home-page-button {
  height: 45px;
  padding-top: 10px;
  width: 250px;
  @include border-radius-24;
  @include box-shadow-btn;
  @include transition(0.5s);
  i {
    margin-right: 0;
    margin-left:6px;
    font-size:12px;
  }
  &:hover{
    @include btn-hover-levitation-transform;
    @include box-shadow-btn-hover;
  }
  @include respond-to(phone, tablet) {
    margin-top: 20px;
  }
}

.home-page-button-row {
  margin-top: 40px;
}

.home-page-illustration1 {
  max-width: 100%;
}

.home-page-section-title-smaller {
  font-size: 40px;
}

.home-page-editor-text {
  max-width: 20em;
  margin-bottom: 25px;

  @include respond-to(phone, tablet) {
    margin-left: auto;
    margin-right: auto;
  }

  @include respond-to(laptop, desktop) {
    margin-left: auto;
  }
}

.home-page-editor-video {
  .editor-img-wrapper {
    border-radius: 24px;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    img {
      max-width: 500px;
      height: auto;
    }

  }
  @include respond-to(phone, tablet) {
    text-align: center;
    .editor-img-wrapper {
      justify-content: center;
    }

  }
}

.home-page-editor-iframe {
  @include respond-to(phone) {
    width: 288px;
    height: 162px;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(tablet) {
    width: 368px;
    height: 207px;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to(laptop) {
    width: 400px;
    height: 225px;
  }
  @include respond-to(desktop) {
    width: 560px;
    height: 315px;
  }
  display: block;
}

.home-page-editor-features-title {
  font-size: 34px;
  margin-top: 50px;

  @include respond-to(laptop, desktop) {
    margin-bottom: 50px;
  }
}

.home-page-editor-features-row {
  @include respond-to(phone, tablet) {
    margin-bottom: 25px;
  }
  @include respond-to(laptop, desktop) {
    margin-bottom: 75px;
  }
}

.home-page-editor-img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-page-editor-features-text {
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
}


.home-page-aod-features {
  @include respond-to(phone, tablet) {
    display: flex;
    justify-content: center;
  }
}

.home-page-aod-features ul li {
  margin-bottom: 30px;

  &.last {
    margin-bottom: 0px;
  }
}

.home-page-aod-icon {
  top: -0.3em;
  left: -2.5em;
}

.home-page-aod-item {
  font-weight: 500;
}

.home-page-aod-text {
  max-width: 30em;
  @include respond-to(phone, tablet) {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }
}

.home-page-about-pcf-text {
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  margin-bottom: 40px;

  @include respond-to(phone, tablet) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include respond-to(laptop, desktop) {
    line-height: 30px;
  }
}

.home-page-client-logo {
  max-width: 100%;
  max-height: 250px;
}

#awards {
  img {
    margin: 30px auto;
  }
}
