// 20220323 Current color scheme in place
$charcoal:               #222626;
$gray:                   #9fa0a1;
$snow:                   #f8f4f7;
$white:                  #ffffff;

$plum:                   #5a2747;
$teal:                   #008575;
$amaranth:               #f3254d;
$lime:                   #80bd3e;
$cream:                  #fffdf8;

//Neutrals & Overlays - Text, backgrounds, panels, form controls, Illustrations
$Text:                   #2E262C;
$Grey100:                #9fa0a1;
$Grey200:                #F9F6F8;
$Grey300:                #f8f4f7;
$Grey400:                #E9E0E7;
$Grey500:                #E5D8E0;
$Grey600:                #CEBDC9;
$Grey700:                #C6B0C0;
$Grey800:                #BDA4B6;
$Grey900:                #93778B;

//Primary - "Teal" Call To Action, Nav elements emphasize, etc...
$Primary:                #008575;
$Primary050:             #e5fdf8;
$Primary100:             #d7fff7;
$Primary300:             #ACD5CA;
$Primary400:             #59AA95;
$Primary500:             #30957B;
$Primary700:             #007c6e;
$Primary900:             #184B3E;

//Accent - Branding purple ("Plum"). These colors can be used to distinguish elements between them such as tags.
$Accent:                 #542A46;
$Accent100:              #FCF7FA;
$Accent200:              #EFE9ED;
$Accent300:              #FAE0FA;
$Accent400:              #F5C2F5;
$Accent500:              #F5C2F5;
$Accent600:              #9C7D91;
$Accent700:              #8C687E;
$Accent800:              #7B526C;
$Accent900:              #6B3D59;

//Semantics Colors - help identify status, see actions, locate help, and understand next steps.
// Positive : success, eye grabbing...
$Success:                #99CA65;
$Success100:             #F2F8EC;
$Success300:             #D9EBC5;
$Success400:             #8DC451;
$Success500:             #80BD3E;
$Success700:             #405F1F;
$Success900:             #263913;

// Info
$Info:                   #008793;
$Info100:                #C7F9ED;
$Info300:                #92F4E4;
$Info400:                #59DED3;
$Info500:                #008793;
$Info700:                #004F69;
$Info900:                #002946;

// Warning
$Warning:                #F18950;
$Warning100:             rgb(251, 245, 201);
$Warning300:             #F8EA94;
$Warning400:             #F4A173;
$Warning500:             #B9777C;
$Warning700:             #935156;
$Warning900:             #3D1214;

// Negative : Error, permanent removal
$Error:                #F15068;
$Error100:             #FFECEF;
$Error300:             #FBCBD2;
$Error400:             #F8A8B4;
$Error500:             #F47386;
$Error700:             #A73344;
$Error900:             #691622;

// Neon : catchy - use carefully!
$NeonPink:             #FB04A4;
$DarkModeBG:           #592647;
$NeonCTA:              #00ffe4;
$NeonWarning:          #fd4d00;
$NeonDanger:           #f15068;
$NeonPurpleLight:      #d950f1;
$Error900:             #691622;


// Map color names to tuples of (color, alternate_color) where alternate_color is the text color to use when color is the background
$colorscheme: (
  "plum": ($Accent900, $white),
  "teal": ($Primary, $white),
  "amaranth": ($Warning, $white),
  "lime": ($Success, $white),

  "charcoal": ($charcoal, $white),
  "gray": ($gray, $white),
  "snow": ($snow, $plum),
  "white": ($white, $charcoal),
  "Warning300": ($Warning300, $Text),
  "Primary300": ($Primary300, $Text),
  "Success300": ($Success300, $Text),

  "text": ($Text, $white),
  "grey100": ($Grey100, $white),
  "grey200": ($Grey200, $white),
  "grey300": ($Grey300, $Text),
  "grey400": ($Grey400, $Text),
  "grey500": ($Grey500, $Text),
  "grey600": ($Grey600, $Text),
  "grey700": ($Grey700, $Text),
  "grey800": ($Grey800, $white),
  "grey900": ($Grey900, $white),

  "primary":    ($Primary, $white),
  "primary050": ($Primary050, $Primary),
  "primary100": ($Primary100, $Primary),
  "primary300": ($Primary300, $Text),
  "primary400": ($Primary400, $Text),
  "primary500": ($Primary500, $white),
  "primary700": ($Primary700, $white),
  "primary900": ($Primary900, $white),

  "accent":    ($Accent, $white),
  "accent100": ($Accent100, $Accent),
  "accent200": ($Accent200, $Accent),
  "accent300": ($Accent300, $Accent),
  "accent400": ($Accent100, $Text),
  "accent500": ($Accent500, $Text),
  "accent600": ($Accent600, $white),
  "accent700": ($Accent700, $white),
  "accent800": ($Accent800, $white),
  "accent900": ($Accent900, $white),

  "success":    ($Success, $Text),
  "success100": ($Success100, $Text),
  "success300": ($Success300, $Text),
  "success400": ($Success400, $white),
  "success500": ($Success500, $white),
  "success700": ($Success700, $white),
  "success900": ($Success900, $white),

  "info":    ($Info, $white),
  "info100": ($Info100, $Text),
  "info300": ($Info300, $Text),
  "info400": ($Info400, $white),
  "info500": ($Info500, $white),
  "info700": ($Info700, $white),
  "info900": ($Info900, $white),

  "warning":    ($Warning, $white),
  "warning100": ($Warning100, $Text),
  "warning300": ($Warning300, $Text),
  "warning400": ($Warning400, $white),
  "warning500": ($Warning500, $white),
  "warning700": ($Warning700, $white),
  "warning900": ($Warning900, $white),

  "error":    ($Error, $white),
  "error100": ($Error100, $Text),
  "error300": ($Error300, $Text),
  "error400": ($Error400, $white),
  "error500": ($Error500, $white),
  "error700": ($Error700, $white),
  "error900": ($Error900, $white),

  "neonpink":    ($NeonPink, $white),
  "darkmodebg": ($DarkModeBG, $white),
  "neoncta": ($NeonCTA, $Text),
  "neonwarning": ($NeonWarning, $white),
  "neondanger": ($NeonDanger, $white),
  "neonpruplelight": ($NeonPurpleLight, $white),

);

$body-bg:                #ffffff;
$text-color:             $charcoal;
$brand-primary:          $plum;

$link-color:            $teal;
$link-hover-color:      $lime;

$font-family-sans-serif:  'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
$font-family-serif:  'Peachi Regular', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
$font-family-serif-bold: 'Peachi Ultra', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
$first-level-headings-font-family:    'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
$second-level-headings-font-family:    'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
$font-size-normal: 16px;
$font-size-small: 14px;
$font-size-tiny: 13px;
$font-size-mini: 10px;

$font-size-mega: 78px;
$font-size-jumbo: 62px;
$font-size-h1: 36px;
$font-size-h2: 24px;
$font-size-h3: 20px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-normal: 22px;

// Animations
$use-fadeInUp       : true;
$use-fadeInDown     : true;
$use-fadeInLeft     : true;
$use-fadeInRight    : true;
$use-pulse          : true;


// Breakpoints

// Map names to the min-size for breakpoints.  Must be ordered smallest to largest
$breakpoints: (
  phone: 0,
  tablet: 756px,
  laptop: 1024px,
  desktop: 1440px,
);
// calc max-size for breakpoints
$breakpoints-max: (
  phone: map-get($breakpoints, tablet) - 1,
  tablet: map-get($breakpoints, laptop) - 1,
  laptop: map-get($breakpoints, desktop) - 1,
  desktop: 0,
);

$max-container-size: 1440px;


// Bootstrap variables for breakpoints -- eventually it would be nice to get rid of this stuff
$screen-md-max:           map-get($breakpoints-max, laptop);
$screen-sm-max:           map-get($breakpoints-max, tablet);
$screen-xs-max:           map-get($breakpoints-max, phone);

$screen-lg-min:           $max-container-size;
$screen-md-min:           map-get($breakpoints, laptop);
$screen-sm-min:           map-get($breakpoints, tablet);

$container-xl: 1600px;
$container-lg: 1400px;
$container-md: 1000px;
$container-sm: 750px;

// Form states and alerts
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             $lime;
$state-success-bg:               #dff0d8;
$state-success-border:           $lime;

$state-info-text:                $teal;
$state-info-bg:                  #E5F2F0;
$state-info-border:              $teal;

$state-warning-text:             $plum;
$state-warning-bg:               #F7EFF5;
$state-warning-border:           $plum;

$state-danger-text:              $amaranth;
$state-danger-bg:                #FAEFF4;
$state-danger-border:            $amaranth;

// z-index values
$z-index-behind: -1;
$z-index-front: 1;
// page layer
$z-index-header: 10;
$z-index-action-bar: 12;
// modal dialog layer
$z-index-overlay: 100;
$z-index-modal: 110;
// top layer
$z-index-menu: 200;
$z-index-tooltip: 201;

$primary-nav-height: 60px;
$secondary-nav-height: 48px;

