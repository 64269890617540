#page-header {
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: $z-index-header;
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0,0,0,0.1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include transition();

  @include respond-to(phone) {
    position: fixed;
  }
  &.nav-up {
      top: -70px;
    @include respond-to(phone) {
      top: 0px;
    }
  }
}


