select.select {
  // Style for our select before we replace it with the select2 box.  The goal
  // here is to have the transition be as smooth as possible
  // visibility: hidden;
  height: $line-height-normal + 18px;
}

.select2 {
  height: 38px;
}

.select2-dropdown--above {
  margin-top: 0 !important;
}

.select2-container.select2-container--amara {
  width: 100% !important;
  @include border-radius-8;

  // Box around the selected options and disclosure button
  .select2-selection {
    display: flex;
    align-items: stretch;
    outline: 0; // We create our own focus ring below
    border: 2px solid $Grey400;
    background-color: #fff;
    height: 39px;
    font-size: 12px;
    @include border-radius-8;
    &:hover, &:active, &:focus, &:focus-visible {
      outline: none !important;
      border: 2px solid $Primary;
    }
    &[disabled], &:disabled {
      color: dark($gray);
    }
  }

  &.select2-container--disabled .select2-selection {
    cursor: not-allowed;
  }

  // Selected option
  .select2-selection__rendered {
    color: $charcoal;
    padding: 7px 8px;
    margin: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    .secondary {
      color: dark($gray);
    }
  }
  &.select2-container--disabled .select2-selection__rendered {
    color: light($gray);
  }

  // Selected option container for multiple choice selects
  .select2-selection__rendered__multiple {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
    height: 36px;
    @include border-radius-8;
  }
  &.select2-container--open .select2-selection__rendered__multiple {
    outline: none !important;
    height: auto;
  }

  // Single selected option for multiple choice selects
  .select2-selection__choice {
    position: relative;
    color: $white;
    background: $teal;
    // border: 1px solid $Grey400;
    @include border-radius-8;
    list-style-type: none;
    margin: 4px;
    padding: 3px 30px 0 8px;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:12px;
  }
  .select2-selection__choice__remove {
    position: absolute;
    z-index: $z-index-front;
    right: 0;
    padding: 2px 8px;
    margin-top: -2px;
    color: $white;

    &:hover {
      color: $amaranth;
    }
  }

  /* indicator used in multiple selects to show how many selections are selected */
  .select2-selection__choice__count {
    color: $gray;
    padding: 7px 4px;
    background: $white; // customize this if the selector has a different background color
    white-space: nowrap;
    font-size:12px;
  }

  // Displayed when no options are selected
  .select2-selection__placeholder {
    color: $gray;
    &:hover {
      color: $Primary;
    }
  }

  .select2-selection__rendered__multiple .select2-selection__placeholder {
    margin-top: 7px;
  }

  // The disclosure button
  .select2-selection__arrow {
    display: block;
    background: $snow;
    padding: 0 5px;
    pointer-events: none;
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;
    color: $Text;
    border-left: 1px solid light($gray);
  }

  .select2-selection__arrow b {
    font-family: 'amara' !important;
    display: inline-block;
    font-size: 20px;
    @include font-weight-normal;
    line-height: 36px;
    vertical-align: middle;
    transform: rotate(90deg);
    @include transition(0.2s);
    &:after {
      @extend .icon-arrow:before;
      speak: none;
    }
  }
  &.select2-container--open .select2-selection__arrow b {
    transform: rotate(270deg);
  }

  // Dropdown
  .select2-dropdown {
    // margin-top:-39px;
    border: 2px solid $Grey400;
    @include border-radius-8;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  }

  // Searchbox at the top of the dropdown
  .select2-search--dropdown {
    padding: 4px 0px;
  }
  .select2-search__field {
    padding: 4px 8px;
    color: $charcoal;
    border: none;
  }
  // Items in the dropdown
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option {
    display: block;
    padding: 6px 18px;

    .secondary {
      color: dark($gray);
    }
  }
  .select2-results__option--highlighted {
    color: $teal;
    .secondary {
      color: $white;
    }
  }
  .select2-results__option[aria-selected=true] {
    border-left: 3px solid $teal;
    padding-left: 15px;
  }
  .select2-results__option .avatar {
      margin-right: 20px;
  }
  .select2-results__option--border {
      border-top: 1px solid light($gray)
  }

  // selection handling
  &.select2-container--focus:not(.select2-container--open) {
    .select2-selection {
      // Match the focus style to our other fields
      // TODO we should refactor this into a SASS mixin class
      border: 2px solid $teal;
      outline: none !important;
      border-shadow:none;
    }
  }
}

// Filter version of the select
.select2-container.select2-container--amara.selectFilter {
  .select2-selection {
    font-size: $font-size-small;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 40px 6px 8px;
    min-height: 31px;
  }

  .select2-selection__arrow {
    display: none;
  }
  .select2-selection__rendered {
    padding: 0 8px;
  }
  .select2-selection__rendered:after {
    display: inline-block;
    margin-left: 5px;
    @include font-weight-normal;
    font-family: 'amara' !important;
    @extend .icon-arrow:before;
    speak: none;
    transform: rotate(90deg);
    @include transition(0.2s);
  }
  &.select2-container--open .select2-selection__rendered:after {
    transform: rotate(270deg);
  }

}
