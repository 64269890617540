.buttonField {
  display: flex;
  input {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}

.buttonField-button {
  flex-grow: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 10px 30px;
  &.sub{
    padding: 7px 30px;

  }
}
