
  #about {
    h2, h3, p {
      font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    }
    .illustration {
      height: max-content;
      width: 500px;
      &.hand-left {
        width: 609px;
        top: -23vh;
        z-index: 9;
        left: -68px;
      }
      &.earth-right {
        right:-25px;
      }
    }
    .intro {
      h1 {
        font-family: 'Peachi Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
        font-weight: 700;
        font-size:72px;
        line-height: 80px;
        text-align:center;
      }
      .tag {
        text-transform:uppercase;
        letter-spacing: 0.2em;
        font-size:18px;
        line-height:28px;
      }
      p {
        font-size:28px;
        line-height:34px;
        text-align:center;
      }
    }
    h1, h2, h3 {
      font-weight:500;
    }
    .img-container {
      height:400px;
    }
    .item {
      background: #fff;
      height: auto;
      min-height: 180px;
      @include border-radius-12;
      margin-bottom:2%;
      margin-right: 2%;
      box-shadow: 1.4px 4.5px 3.1px rgba(0, 0, 0, 0.016),
      11px 36px 25px rgba(0, 0, 0, 0.03);
    }
    #amara-funding {
      min-height: 460px;
      padding: 20px 0 60px 0;
      background:#fff;
      .content {
        padding-top:65px;
      }
    }
    #amara-difference{
      min-height: 620px;
      padding: 20px 0;
      background:#fff;
      .content {
        padding-top:65px;
        &.tech {
          padding-top: 15px;
          li {
            margin-top:5px;
          }
        }
      }
    }
  }
#our-mission {
  padding: 20px 0 50px 0;

  a {
    margin: 0 auto;
    display: block;
    width: 285px;
  }

}

#amara-community, #core-team {
  img {
    margin: 20px auto;
  }
}

#core-team {
  svg {
    width: 250px;
    margin: 0 auto;
    display: block;
  }
  .bio-card {
    min-height: 290px;
    height:auto;
    overflow:hidden;
    background: #fff;
    border-radius: 12px;
    margin: 8% 2%;
    padding: 10px 30px;
    box-shadow: 1.4px 4.5px 3.1px rgba(0, 0, 0, 0.016),
    11px 36px 25px rgba(0, 0, 0, 0.03);
    text-align: center;
  }
  img {
    width: 80px;
    height:80px;
  }
  h3 {
    font-size:16px;
    margin-bottom: 0;
    text-align:center;
  }
  h4 {
    font-size:13px;
    margin-top: 5px;
  }
  p {
    margin-bottom: 20px;
  }
  .member-email {
    font-size:13px;
    text-decoration:none;
  }
  .member-name{
    @include font-weight-bold;
    & span.member-pronouns {
    @include font-weight-bold;
    }
  }
  .member-pronouns {
    font-size:11px;
  }
  .member-title {
    @include font-weight-bold;
  }
  .member-loaction {

  }
  .member-image {}
  .member-email {}


 /* Accordion styles */
 .accordion {
  width: 100%;
  margin: 0 auto;
  @media(max-width: $screen-xs-max) {
   padding-left: 6%;
  }

  section {
    padding:20px 0;
  }

  &__heading {
    font-size:28px;
    line-height: 34px;
    position: relative;
    display: block;
    padding: 10px 50px;
    margin: 0;
    cursor: pointer;
    &:hover{
      opacity:0.8;
    }
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 3px;
      background: #5a2747;
      top: 50%;
      left: 20px;
      transition: all 0.4s;
    }
    &:before {
      .accordion__checkbox:checked ~ & {
        transition: all 0.4s;
        transform: rotate(90deg);
        opacity: 0;
      }
    }
    &:after {
      transform: rotate(90deg);
      transition: all 0.3s;
      .accordion__checkbox:checked ~ & {
        transform: rotate(180deg);
      }
    }
  }

  &__checkbox {
    display: none;
    position: absolute;
    left: -9999em;

    &:checked ~ .accordion__content {
      max-height: 3000px;
      transition: all 0.4s;
      overflow: visible;
    }
  }

  &__content {
    padding: 0 15px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s;
  }
  &.cookie-consent-details-container {
    width: 400px;
  }
}
}
#expertise {
  background:#fff;
  margin-bottom:5%;
  padding-top:7%;
  .item {
    margin-right:2%;
  }
  padding-bottom:10%;
}
.custom-shape-divider-top-1652250623 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1652250623 svg {
  position: relative;
  display: block;
  width: calc(117% + 1.3px);
  height: 57px;
}

.custom-shape-divider-top-1652250623 .shape-fill {
  fill: #f8f4f7;
}



.custom-shape-divider-bottom-1652265940 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1652265940 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 54px;
}

.custom-shape-divider-bottom-1652265940 .shape-fill {
  fill: #f8f4f7;
}



// Media Queries


@media(max-width: $screen-md-max) {
  #about{
    .intro{
      h1{
        font-size: 42px;
        line-height: 52px;
      }
      p {
        font-size: 22px;
        line-height: 32px;
      }
    }
    .jumbo {
      font-size: 42px;
      line-height: 52px;
    }
    #amara-difference {
      padding: 20px 0 50px 0;
    }
    .item{
      min-height: 214px;
      }
  }
  #core-team{
    .bio-card{
      min-height: 300px;
      padding: 10px;
      margin: 8% 6%;
    }
  }
  #expertise {
    .img-container-fluid{
      display:none;
    }
  }

}

@media(max-width: $screen-sm-max) {
  #about {
    .item{
    min-height: 180px;
    }
  }
  #our-mission {
    .illustration {
      display:none;
    }
  }
  #amara-funding {
    .funding-illustration {
      display:none;
    }
  }
  .hand-left {
    display:none;
  }
  #expertise {
    padding-top:15%;
    .img-container-fluid{
      display:none;
    }
  }
  #core-team{
    .bio-card{
    margin: 6px;
    min-height: 270px;
    padding:5px;
    }
  }
}

@media(max-width: $screen-xs-max) {
  #our-mission {
    .illustration {
      margin-top: 20px;
    }
  }
  #about {
    .item{
    min-height: 180px;
    }
  }
  #expertise {
    .img-container-fluid{
      display:none;
    }
  }

}

