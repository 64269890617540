// Radio fields in use for AV landing form and AOD services form
.switch-field {
  overflow: visible;
  padding-left: 2%;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
float: left;
}

.switch-field label {
  border: 2px solid transparent;
  display: inline-block;
  width: 95%;
  background-color: #fff;
  color:$Text;
  border-radius:12px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 0.3em;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition:    all 0.1s ease-in-out;
  -ms-transition:     all 0.1s ease-in-out;
  -o-transition:      all 0.1s ease-in-out;
  transition:         all 0.1s ease-in-out;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07);
  &.av-field label {
    font-size: 14px;
    height: 70px;
  }
  svg {
    left: 10%;
    position: absolute;
  }
  p {
    font-size:16px;
    text-align:left;
    padding: 0.9em 0.3em 0.3em 6em;
  }
  &.contact {
    border: 2px solid $Grey400;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  }
}

.switch-field label:hover {
  border: 2px solid $Info500;
  cursor: pointer;
  color: $Info500;
  box-shadow:
0 1.1px 1.1px rgba(0, 0, 0, 0.012),
0 2.5px 2.7px rgba(0, 0, 0, 0.017),
0 4.8px 5px rgba(0, 0, 0, 0.02),
0 8.5px 8.9px rgba(0, 0, 0, 0.024),
0 15.9px 16.7px rgba(0, 0, 0, 0.029),
0 38px 40px rgba(0, 0, 0, 0.04)
;
}

.switch-field {
  input:focus + label {
  border: 2px solid $Info500;
  color: $Info500;
  font-family: $first-level-headings-font-family;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
  }
}

.switch-field input:checked {
  + label {
  border: 2px solid $Info500;
  color: $Info500;
  -webkit-box-shadow: none;
  box-shadow: none;
  }
  &:hover {
    border: 20px solid $Info500;
  }
}
