// Layout

// Hide content on load. (FOUT versus FOIT)
.wf-loading {
  nav, .content { opacity: 0; }
}
.wf-active {
  nav, .content { opacity: 1; }
}
.wf-inactive {
  nav, .content { opacity: 1; }
}



.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

.grid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  .item {
    width: 33%;
  }
  .col-* {
    float: none;
  }
}

.break-33 {
  width: 33% !important;
}
.break-50 {
  width: 50% !important;
}
.break-60 {
  width: 60% !important;
}
.break-70 {
  width: 70% !important;
}
.break-80 {
  width: 80% !important;
}
.break-90 {
  width: 90% !important;
}

// column wrap
.col2 {
  column-count: 2;
  column-gap: 40px;
  li {
    break-inside: avoid;
    margin-bottom: 0;
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}
.col3 {
  column-count: 3;
  column-gap: 40px;
  li {
    break-inside: avoid;
    margin-bottom: 0;
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}

.muted, .faded {
  opacity: 0.5;
}

img.pull-left {
  margin-right: 12px;
}
img.pull-right {
  margin-left: 12px;
}

//play button
.play-button {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  &.center {
    justify-content: center;
  }
  &:hover {
    i {
      color: $amaranth;
    }
  }
  i {
    font-size: 40px;
    margin-right: 10px;
  }
}

//caret styles
.caret {
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
}


@media (max-width: $screen-xs-max) and (min-width: 0) {
  .visible-xs {
    display: block!important;
  }
  .hidden-xs {
    display: none!important;
  }
}

@media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
  .visible-sm {
    display: block!important;
  }
  .hidden-sm {
    display: none!important;
  }
}

@media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
  .visible-md {
    display: block!important;
  }
  .hidden-md {
    display: none!important;
  }
}


// Media Queries

@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {
  .grid {
    .item {
      width: 50%;
    }
  }
}

@media(max-width: $screen-xs-max) {
  .grid {
    .item {
      width: 100%;
    }
  }

  // breaks go to full width
  .break-33,
  .break-50,
  .break-60,
  .break-70,
  .break-80,
  .break-90, {
    width: 100% !important;
  }

  // columns stop wrapping
  .col2 {
    column-count: 1;
  }
  .col3 {
    column-count: 1;
  }
}

.hidden {
  display: none;
}

.padding1_left-right {
  padding: 0 3%;
}

.padding3_left-right {
  padding: 0 3%;
}

.padding5_left-right {
  padding: 0 5%;
}

.padding10_both {
	padding: 10% 0;
}

.padding1_both {
	padding: 1% 0;
}

.padding3_both {
	padding: 3% 0;
}

.padding5_both {
	padding: 5% 0;
}
.padding10_top {
	padding-top: 10%;
}
.padding5_top {
	padding-top: 5%;
}
.padding3_top {
	padding-top: 3%;
}
.padding1_top {
	padding-top: 1%;
}
.padding05_top {
	padding-top: 0.5%;
}
.padding10_bottom {
	padding-bottom: 10%;
}
.padding5_bottom {
	padding-bottom: 5%;
}
.margin10_both {
	margin: 10% 0;
}
.margin5_both {
	margin: 5% 0;
}
.margin3_both {
	margin: 3% 0;
}
.margin20_top {
	margin-top: 20%;
}
.margin10_top {
	margin-top: 10%;
}
.margin5_top {
	margin-top: 5%;
}
.margin3_top {
	margin-top: 3%;
}
.margin2_top {
	margin-top: 2%;
}
.margin1_top {
	margin-top: 1%;
}
.margin10_bottom {
	margin-bottom: 10%;
}
.margin5_bottom {
	margin-bottom: 5%;
}
.margin3_bottom {
	margin-bottom: 3%;
}

.margin1_bottom {
	margin-bottom: 1%;
}

.margin20_left {
	margin-left: 20%;
}
.margin10_left {
	margin-left: 10%;
}
.margin5_left {
	margin-left: 5%;
}
.margin3_left {
	margin-left: 3%;
}
.margin2-5_left {
        margin-left: 2.5%;
}
.margin2_left {
	margin-left: 2%;
}
.margin1_left {
	margin-left: 1%;
}


.border-radius-top-only {
  @include border-radius-top-only;
}

.round-corners {
  @include border-radius-6;
}
