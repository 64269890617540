#page-content {
  
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {
  #page-content {
    
  }
}