@import "navigation_old";

.navbar {
  margin: 0;
  padding: 0;
  border: none;
  min-height: 0;
  position: relative;
}

.navbar-container {
  &.primary {
    background: #fff;
  }
  &.secondary {
    background: $Primary100;
    border-top: 1px solid  #6fddbd;
  }
}

// Top-level sections
.navbar-primary, .navbar-secondary {
  display: flex;
  align-items: stretch;
  margin: 0 auto;

  @include respond-to(tablet) {
    max-width: $container-md;
  }
  @include respond-to(laptop) {
    max-width: $container-xl;
  }
  @include respond-to(desktop) {
    max-width: $container-xl;
  }

}

.navbar-primary {
  height: $primary-nav-height;
  align-items: center;
  .navbar-search {
    margin-right: 1%;
    @include respond-to(phone) {
      padding: 0 3%;
    }
    .form-group {
      margin-bottom: 0;
      input {
        padding: 8px 15px;
        border-radius: 30px;
      }
      &.searchbar:after {
        top: 1px;
      }
    }
  }
}

.navbar-secondary {
  height: $secondary-nav-height;
  padding: 0px 4% 0px 3%;
  background-color: $Primary100;
}

.navbar-primaryLinks {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0;

  .navbar-primaryLink {
    display: inline-block;
    line-height: 20px;
    padding: 0 10px;
    margin: 0 2px 0 30px;
    font-size: 16px;
    color: $Text;
    font-family: $first-level-headings-font-family;
    @include no-underline;
    @include font-weight-bold;
    @include transition(0.2s);
    @include respond-to(tablet) {
      text-align: center;
      margin: 0 2px 0 15px;
      padding: 0 6px;
      font-size: 14px;
    }
    @include respond-to(phone) {
      height: 40px;
      margin: 0;
      text-align: center;
    }
    .amara-logo {
      @include respond-to(phone) {
        display: none;
      }
    }
    &.logo {
      background: $white;
      padding: 0 12px;

      img {
        width: $primary-nav-height;
        height: $primary-nav-height;
      }

      &:hover, &:focus {
        background: $white;
      }
    }

    &.highlight {
      background: $lime;
    }
    &.sign-up {
      max-width: 144px;
      text-align: center;
    }
    &.user-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;
    }
    &:hover, &:focus {
      color: $Primary;
      outline: none;
    }
    &.subtitlingServicesnavbar {
      .fa {
        font-size: 14px;
      }
    }
    &.active {
      background: $white;
      color : $Primary;
      font-family: $first-level-headings-font-family;
      @include font-weight-bold;
      &.subtitlingServicesnavbar {
        &:focus, &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.navbar-user {
  display: flex;
  align-items: center;
  gap: 10%;
  margin: 0 3%;
  flex-direction: row;
  justify-content: space-between;
  @include respond-to(phone) {
    text-align: center;
  }
  li {
    .button {
      @include respond-to(phone) {
        text-align: center;
        display: block;
        width: 80%;
        max-width: 180px;
        margin: 0 auto 30px auto;
      }
    }
    a.navbar-primaryLink {
      line-height: 20px;
      font-size: 16px;
      color: $Text;
      font-family: $first-level-headings-font-family;
      @include no-underline;
      @include font-weight-bold;
      &:hover {
        color: $Primary;
      }
    }
    &.authenticated-user-menu-link {
      padding: 0 20px;
      ul {
        li.user-menu {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;
          @include respond-to(phone) {
            justify-content: center;
            align-items: center;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.navbar-secondaryLogo {
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
  font-size: $font-size-h3;

  a {
    @include no-underline;
    color: $plum;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.navbar-secondaryLinks {
  display: flex;
  align-items: center;
  margin: 0;

  li {
    height: 100%;

    a {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}


.navbar-secondaryLogo {
  margin-right: 20px;

  img {
    height: $secondary-nav-height - 16px;
    border-radius:8px;
  }
}



.navbar-secondaryLink {
  color: $plum;
  font-weight: $font-weight-normal;
  margin-left: 20px;
  @include no-underline;
  &.active {
    font-family: $first-level-headings-font-family;
    font-weight: $font-weight-bold;
  }
}

.navbar-mobileHeader {
  display: none;
}

.navbar-mobileHeaderLogo {
  flex-grow: 1;
  img {
    height: 25px;
  }
}

.navbar-mobileHeaderButton {
  width: 50px;
  height: 50px;
  font-size: 24px;
  color: $Accent;

}
 #simply-burger {
   width: 30px;
   height: 30px;
   transform: rotate(0deg);
   transition: .25s * 2  ease-in-out;
   cursor: pointer;
   margin: 0.5em;
 }

 //Neat trick to draw the menu lines. Spans with display:block/height and poof!
 #simply-burger span {
   display: block;
   position: absolute;
   height: 4px;
   width: 100%;
   background: $Accent;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   transform: rotate(0deg);
   transition: .25s ease-in-out;
 }

 //Align spans to be below each other
 #simply-burger span:nth-child(1) {
   top: 0px;
 }

 #simply-burger span:nth-child(2) {
   top: 12px;
 }

 #simply-burger span:nth-child(3) {
   top: 24px;
 }

 //On click (see JS/jQuery) rotate 1st and 3rd span 135/-135 degrees in order to draw an X symbol. You can achieve the same result with -45/45 degrees, but this is more catchy for the user.
 #simply-burger.open span:nth-child(1) {
   top: 12px;
   transform: rotate(135deg);
 }

 //We only need 2 span lines for the X, so we just gonna throw the middle line away.
 #simply-burger.open span:nth-child(2) {
   opacity: 0;
   left: -60px;
 }

 #simply-burger.open span:nth-child(3) {
   top: 12px;
   transform: rotate(-135deg);
 }

@include respond-to(phone) {
  #main-nav {
      display: none;
  }

  .navbar-mobileHeader {
    display: flex;
    height: 50px;
    padding-left: 20px;
    align-items: center;
  }

  .navbar-primary, .navbar-secondary, .navbar-primaryLinks, .navbar-user {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    flex-wrap: wrap;
  }

  .navbar-secondaryLinks {
    flex-direction: row;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    li {
      a {
        font-size: 12px;
      }
    }
  }

  .navbar-primaryLink.logo {
    display: none;
  }

  .navbar-secondaryLogo {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .navbar-container.primary {
    background: $white;
  }

  .navbar-primaryLink, .navbar-secondaryLink {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 20px;
    font-size: $font-size-normal;
    height: auto;
  }

  .navbar-primaryLink {
    color: $Accent;
    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  .navbar-secondaryLink {
    color: $Accent;
    &:hover, &:focus {
      opacity: 0.8;
    }
  }
}

.dropdownMenu {
  &.dropdown-navbar {
    top: 60px;
    width: 500px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    li {
      flex-basis: 48%;
      margin: 5px;
      &:last-child {
          flex-basis:100%;
      }
      .aod-service-link-navbar {
        text-decoration: none;
        font-weight: 400;
        .aod-services-text-title {
          color: $Text;
        }
      }
      svg {
        float: left;
        width: 30px;
        height: 30px;
        margin-right: 7px;
      }
    }

  }
}
