section {
  position: relative;
  padding: 50px 0;
  margin: 0 auto;
  clear: both;
  width:100%;
  max-width: 1400px;
  &.wide {
    max-width: 1800px;
  }
  &.tight-spacing {
    padding-top: 0;
  }

  &.less-spacing {
    padding-top: 20px;
  }
  // @include respond-to(tablet) {
  //   max-width: $container-sm;
  // }
  // @include respond-to(laptop) {
  //   max-width: $container-md;
  // }
  // @include respond-to(desktop) {
  //   max-width: $container-lg;
  // }
}

.a-tag-font {
  color: $teal;
  @include font-weight-bold;
}

a {
  @extend .a-tag-font;
  text-decoration: underline;
  &:hover {
    color: $lime;
    cursor: pointer;
  }
  &.no-underline {
    @include no-underline;
  }
  &.block {
    display: inline-block;
  }
  &.disabled {
    cursor: default;
    color: light($gray);
    pointer-events: none;
  }
  &.text {
    @include no-underline;
    color: inherit;
    font-weight: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

iframe {
  max-width: 100%;
}

ol, ul {
  list-style: none;
  padding: 0;
}

header {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  &.borderless {
    border-bottom: none;
  }
  h1, h2, h3, h4 { margin: 0; }
}
