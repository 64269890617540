.form-font{
  font-family: 'Peachi Regular', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  h2.jumbo {
    margin-top: 3%;
    font-family: 'Peachi Bold', "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    font-size:62px;
    &.enterprise {
      margin-top:0;
      font-size:54px;
    }
  }
  p.lead {
    font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    font-weight:400;
    font-style: normal;
  }
  h3 {
    font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
  }
  .icons-left-list {
    background: $snow;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    svg {
      margin-top:5px;
    }
  }
  .fa-ul {
    margin: 8%;
  }
  .fa-ul > li > p {
    margin-left: 20px;
    line-height: 32px;
  }
}
.bg-form {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
  h2 {
    font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
    font-weight:700;
    padding: 0 0 0 15px;
  }
  legend {
    background:#fff;
    h3 {
      font-family: 'DM Sans', Lato, "Lucida Grande", Tahoma, Sans-Serif;
      color: $Text !important;
      background: none;
    }
  }
  fieldset {
    .form-group{
      label {
        color: $Text;
      }
      .fieldOptional {
        display:none;
        //So it remained displayed in the rest of the forms until I (Robert) review them and include optional as a placeholder
      }
      &.has-error {
        label {
          color: $Warning;
        }
      }
    }
  }
}
.dynamicFormsetFieldset, .dynamicFormsetFieldset fieldset {
  border: 1px solid #f4f4f7;
  overflow: hidden;
  border-bottom:none;
  margin:0;
  padding-top: 60px;
  &.border-full{
    border-bottom:1px solid #f4f4f7;
  }
  .sublegend {
    margin-left: 5px;
    width: 99.5%;
  }
  .teamSettingsMessaging-localizedForms {
    border-bottom: 1px solid #f4f4f7;
    .sublegend {
      margin-left:0;
      width: 100%;
    }
  }
}

.dynamicFormsetFieldset-footer{
  border: 1px solid #f4f4f7;
  border-top:none;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $Grey400;
  font-size:12px;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    $Grey400;
 opacity:  1;
 font-size:12px;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    $Grey400;
 opacity:  1;
 font-size:12px;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    $Grey400;
 font-size:12px;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    $Grey400;
 font-size:12px;
}

::placeholder { /* Most modern browsers support this now. */
 color:    $Grey400;
 font-size:12px;
}

label {
  display: block;
  max-width: 100%;
  font-size:14px;
  margin-bottom: 0;
  @include font-weight-bold;
  text-align: left;

  &.disabled {
    color: dark($gray);
  }

  &.checkbox-grid {
    padding-bottom: 4px
  }
}
textarea {
  resize: vertical;
}
select {
  appearance: none;
}
input,
select,
textarea {
  display: block;
  line-height: $line-height-normal;
  width: 100%;
  font-size:16px;
  padding: 8px 10px;
  margin: 0;
  border: 2px solid $Grey400;
  background-color: #fff;
  color: $charcoal;
  border-radius:6px;
  @include transition;
  &:hover, &:active, &:focus, &:focus-visible {
    outline: none !important;
    border: 2px solid $Primary;
  }
  &[disabled], &:disabled {
    color: dark($gray);
  }
}

// use this for labels that are intended to be a header for subsequent list of related inputs
label.input-list-label {
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 10px;
}

.form-actions {
  text-align: right;

  &.center {
    text-align: center;
  }
}

.form-actions-top {
  @extend .form-actions;
  margin-bottom: 8px;
}

.fieldOptional {
  color: dark($gray);
}

// placeholders

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: dark($gray);
}
::-moz-placeholder { /* Firefox 19+ */
  color: dark($gray);
}
:-ms-input-placeholder { /* IE 10+ */
  color: dark($gray);
}
:-moz-placeholder { /* Firefox 18- */
  color: dark($gray);
}


.buttonFile {
  position: relative;
  overflow: hidden;
  border: 1px solid light($gray);
  border-left-width: 0;
  padding: 8px 10px;
  line-height: $line-height-normal;
  margin: 0;
  font-size: $font-size-small;
  @include font-weight-normal;
  background-color: $teal;
}
.buttonFile input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: white;
  cursor: inherit;
  display: block;
}
.fileUpload-text {
  border-right-width: 0;
  background-color: light($gray);
}
//fix to override standard bootstrap radio hidden behind styled radio (needed less margin so borders remain hidden) see styleguide/compoundField
.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"]{
  margin-left: -18px;
}

input[type="radio"],
input[type="checkbox"] {
  width:20px;
  height: 20px;
  float: left;
  accent-color: $Primary;
  &:checked {
    accent-color: $Primary;
    &:hover {
      accent-color: $Primary;
    }
  }
}

.form-check-label {
  padding-top: 3px;
}

.checkbox, .radioSelect {
  margin: 0;
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    margin-left: 0;
  }
  label {
    min-height: 20px;
    padding-left: 0px;
    margin-bottom: 0;
    @include font-weight-normal;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;

    &.center {
      justify-content: center;
    }
  }
  &:hover{
    cursor:pointer;
  }
}

.a11y {
  .checkbox-icon, .radio-icon {
    display: none;
  }
  input[type='checkbox'], input[type='radio'] {
    position: absolute;
    display: block;
  }

  input[type='checkbox'] + label, input[type='radio'] + label {
    display: block;
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox'] + label::before, input[type='radio'] + label::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    background: #ffffff;
    border: 1px solid light($gray);
    border-radius: 4px;
    cursor: pointer;
  }

  input[type='radio'] + label::before {

    border-radius: 50%;
    &:before {
      display: block;
      content: "";
      position: absolute;
      height: 14px;
      width: 14px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: transparent;
    }
  }
  input[type='checkbox']:checked + label::before, input[type='radio']:checked + label::before {
    background: #5ac5c9;
    font-family: 'amara';
    @extend .icon-check:before;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 16px;
    margin-right: 8px;
    text-align: center;
    color: #fff;
  }
    // &:before {
    //   font-family: 'amara';
    //   @extend .icon-check:before;
    //   display: block;
    //   position: absolute;
    //   height: 18px;
    //   width: 18px;
    //   font-size: 16px;
    //   line-height: 16px;
    //   top: 1px;
    //   left: 0;
    //   text-align: center;
    //   color: #fff;
    // }
    // &:hover{
    //   background-color: $Primary;
    //   opacity:0.8;
    // }

  input[type='checkbox']:focus + label::before, input[type='radio']:focus + label::before {
    outline: #5d9dd5 solid 1px;
    box-shadow: 0 0px 8px #5e9ed6;
  }
  input[type='checkbox'], input[type='radio'] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
  input[type='checkbox']:disabled + label, input[type='radio']:disabled + label {
    color: #575757;
  }

  input[type='checkbox']:disabled + label::before, input[type='radio']:disabled + label::before {
    background: #ddd;
  }
  .presetOrCustom-textInput {
    display: inline-block;
    border: 2px solid $Grey400;
  }

}
.radio + .radio, .checkbox + .checkbox {
  // undo bootstrap's -5px
  margin-top: 0;
}

.radioSelect {

  > li {
    padding-bottom: 4px;
  }

  &.inlineChoices {
    display: flex;

    > li {
      padding-left: 0px;
      padding-right: 20px;
      padding-top: 10px;
    }
  }
}

.radio-icon, .checkbox-icon {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  background: #ffffff;
  border: 1px solid light($gray);
  border-radius: 4px;
  cursor: pointer;
}
.radio-icon {
  border-radius: 50%;
  &:before {
    display: block;
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: transparent;
  }
}
input:checked + label .radio-icon:before {
  background-color: $Primary;
}

input:checked + label .checkbox-icon {
  background-color: $Primary;
  &:before {
    font-family: 'amara';
    @extend .icon-check:before;
    display: block;
    position: absolute;
    height: 18px;
    width: 18px;
    font-size: 16px;
    line-height: 16px;
    top: 1px;
    left: 0;
    text-align: center;
    color: #fff;
  }
  &:hover{
    background-color: $Primary;
    opacity:0.8;
  }
}

input[type=checkbox][disabled] + label, input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  color: dark($gray);
  .checkbox-icon {
    cursor: not-allowed;
  }
}

input:checked[disabled] + label, input:checked:disabled + label {
  .checkbox-icon {
    background: inherit;
    border: 0;

    &:before {
      color: $lime;
      font-size: 18px;
      line-height: 18px;
      width: 18px;
      height: 18px;
    }
  }
}

.radio {
    input:disabled + label, input[disabled] + label {
        color: dark($gray);
    }
}

.radioItem-subfield {
  margin-left: 28px;
  margin-top: 4px;
  margin-bottom: -20px; // removes the default margin for form-group
  &.slideDown {
    display: none;
  }
}

.checkbox-spacer {
    display: inline-block;
    width: 26px;
}


.multiField-label {
  float: left;
  width: 120px;
  text-align: right;
  @include font-weight-normal;
  line-height: 40px;
}

.multiField-input {
  max-width: 400px;
  margin-left: 140px;
  margin-right: auto;
  padding-bottom: 8px;
  background: $snow;

  &.last {
    padding-bottom: 0;
  }

  &.checkbox {
    margin-left: 0;
  }
}

.multiField-separator {
  display: none;
}

.multiField-rowEnd {
  clear: both;
}

@supports (display: grid) {
  @include respond-to(tablet, 0) {

    .multiField {
      display: grid;
      grid-template-rows: auto auto;
      grid-auto-flow: column;

      @for $i from 1 through 5 {
        &.columns-#{$i} { grid-template-columns: repeat(#{$i}, minmax(100px, auto)) 1fr; }
      }
      &.checkboxes {
        margin-top: 4px;
        grid-template-rows: auto;
      }
    }

    .multiField-label {
      text-align: left;
      padding-left: 16px;

      &:first-of-type {
        padding-left: 0;
      }
    }

    .multiField-input {
      position: relative;
      border: 0;
      margin: 0;
      padding: 10px 32px;
      border-right: 4px solid $white;
      &:first-of-type {
        padding-left: 16px;
      }
      &.noborder {
        border-right: 0;
      }
    }

    .multiField-inputExtender {
      grid-row: -2 / -1;
      background: $snow;
    }

    .multiField-rowEnd {
      display: none;
    }

    .multiField-separator {
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 20px;
      height: 100%;
      z-index: $z-index-front;

      &.checked {
        display: none;
        color: light($gray);
      }

      &.unchecked {
        color: $white;
      }

      &.ordered {
        margin-left: -10px;
        color: $white;
      }
    }

    .multiField-input.checked {
      background: light($gray);

      .multiField-separator.checked {
        display: block;
      }
      .multiField-separator.unchecked {
        display: none;
      }
    }

  }
}

.subtitleVisibilityLabel {
  width: 155px;
}

.subtitleVisibilityInput {
  margin-left: 160px;
}

@include respond-to(tablet, 0) {
  .subtitleVisibilityInput {
    margin: 0;
  }
}


.form-group {

  &.has-success {
    .form-control, label, .helpBlock, .input-control {
      border-color: $state-success-border;
      color: $state-success-text;
    }
    label.disabled {
      color: dark($gray);
    }
  }
  &.has-warning {
   .form-control, label, .helpBlock, .input-control {
     border-color: $Warning;
     color: $Warning; }
  }
    label.disabled {
      color: dark($gray);
    }
  &.has-error {
    .form-control, label, .helpBlock, .input-control {
      border-color: $Warning;
      color: $Warning;
    }
    label.disabled {
      color: dark($gray);
    }
    .helpBlock {
      i {
        padding: 0 5px 0 0;
      }
    }
  }
}
.bg-teal, .bg-plum {
  .form-group.has-error {
    .helpBlock {
      background: $white;
      padding: 4px 16px;
      margin-top: 0;
      color: $Warning;
    }
  }
}



.helpBlock {
  display: block;
  font-size: $font-size-mini;
  line-height: 14px;
}

.helpBlock-radio {
  margin-left: 24px;
  margin-top: 0;
}

.helpList {
  margin-left: 24px;
  font-size: $font-size-tiny;
  list-style-type: disc;
  list-style-position: inside;
}

.radioItem-help {
  font-size: $font-size-small;
  color: dark($gray);
  margin-left: 28px;
}

fieldset {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  border-top: none;
  border-bottom: none;
  padding: 40px 20px 10px 15px;
  // Note: padding-top needs to make room for legend, which is absolutely positioned
}

legend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 9px 15px;
  margin: 0;
  border: none;
  background-color: $plum;
  color: $cream;

  display: flex;
  align-items: middle;

  h3 {
    color: inherit!important;
    margin: 0;
    line-height: 1;
    flex-grow: 1;
  }
}

.legend-extra {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  .button-line {
    padding:5px;
    border-radius:6px;
  }
  .button {
    border-radius: 6px;
    padding:3px 15px;
  }
}

fieldset {
  // Handle nested fieldsets
  fieldset.full-width {
    margin: 0 -20px 0 -20px;
    border: none;
  }
  fieldset.flush-top {
    margin-top: -20px;
  }

  fieldset legend {
    background-color: $snow;
    color: $charcoal;
  }

}

.fieldset-bottom {
  border-top: none;
}

.fieldset-footer {
  border-top: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.fieldset-footer-shadow {
  @extend .fieldset-footer;
  background-color: #f4f4f7;
  display: flex;
  color: #9fa0a1;
  padding-top: 10px;
}

.fieldset-backtotop-container {
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: pointer;
}

.fieldset-backtotop-container:hover > .small {
  text-decoration: underline;
}

.fieldset-backtotop-chevron {
  color: #c6c6c7;
  margin-right: 6px;
}

.fieldsetHelpBlock {
  margin-top: -20px;
  margin-bottom: 20px;
  font-size: $font-size-tiny;
  color: $charcoal;
}

.fieldsetError {
  margin-top: -10px;
  margin-bottom: 10px;
  color: $amaranth;
}

.buttonFile {
  text-transform: none;
  font-family: $font-family-sans-serif;
  border-radius:0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.saveChangesButton {
  visibility: hidden;

  .noSaveChangesButton & {
    // This class forces the save changes button to be always hidden.  Use it
    // to manually control when the button should appear, for example on the
    // messaging settings form we don't want one of the button to appear unless
    // there is a localized form.
    visibility: hidden !important;
  }

}

.imageInput-text {
  min-width: 0; // bug fix for Firefox not respecting flex shrinking
}

.imageInput-previewContainer {
  background: url("../img/image-upload-bg.png");
  margin: 4px 0 16px 0;
}

.imageInput-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.imageInput-input {
  position: relative;
}

.imageInput-buttonContainer {
  position: relative;
}

.imageInput-fileInput {
  z-index: $z-index-front;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  border: 0;
  filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.imageInput-displayedInput {
  position: absolute;
}

.dynamicHelp {
  display: none;
  &.shown {
    display: block;
  }
}

.narrowField {
  max-width: 400px;
}

.dependentField {
  padding-left: 30px;
  margin-top: -8px;
}

.expandableFieldset {
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 52px;

  &.expanded  {
    padding-bottom: 20px;
    padding-top: 70px;
  }
}

.expandableFieldset-content {
  display: none;
}

#info-error {
  text-align: center;
  color:$Warning;
  &.display {
    display:block;
  }
  &.hide {
    display:none;
  }

}

@import "compound_field";
@import "button_field";
@import "preset_or_custom";
@import "table_select";
@import "payment";
