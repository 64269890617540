
button {
  border: none;
  background-color: transparent;
  &:disabled {
    color: $disabled-gray-bg;
  }
  padding: 0;
  &.user-menu {
    max-width: 200px;
    width: auto;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


@mixin button-hover {
  @include transition(0.4s);
  &:hover, &.hover, &:focus {
    color: #fff;
    border-color: $lime;
    background-color: $lime;
  }
}

.button {
  display: inline-block;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 30px;
  font-family: $font-family-sans-serif;
  font-size: $font-size-normal;
  @include font-weight-normal;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  white-space: nowrap;
  line-height: 1.42857;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  background-color: $Primary;
  color: #fff;
  @include border-radius-24;
  &:hover, &.hover, &:focus {
    opacity:0.9;
  }
  i {
    margin-right: 0;
    margin-left:6px;
    font-size:14px;
  }
  @include no-underline;
  &.block {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  &.blockLeft {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 40px;
  }
  &.secondary {
    color: $Text;
    font-family: $first-level-headings-font-family;
    font-weight: 700;
    border: 2px solid $Grey400;
    background-color: $Grey200;
    &:hover, &.hover, &:focus {
      background-color: #fff;
      border: 2px solid $Primary;
      color: $Primary;
    }
  }
  &.pad-bottom {
    margin-bottom: 4px;
  }
  &.small {
    padding: 2px 15px;
    font-size: 13px;
    line-height: 1.5em;
  }
  &.tall {
    padding: 14px 30px;
  }
  &.cta {
    color: #fff;
    font-family: $first-level-headings-font-family;
    font-weight: 700;
    background-color: $Primary;
    // border: 2px solid $Primary900;
    box-shadow: 0 0 0 0 $Primary900;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover, &:focus {
      color: #fff;
      box-shadow: 2px 5px 0 0 $Primary900;
    }
    &:active {
      transform: translateY(2px) translateX(1px);
      box-shadow: 0 0 0 0 $Primary900;
    }
  }
  &.cta-reverse {
    color: $Text;
    font-family: $first-level-headings-font-family;
    font-weight: 700;
    border: 1px solid $Text;
    background-color: #fff;
    &:hover, &.hover, &:focus {
      background-color: $Grey500;
      border: 1px solid $Text;
      color: $Text;
    }
  }
  &.delete {
    color: $white;
    background-color: $amaranth;
    border-color: $amaranth;
    &:hover, &.hover, &:focus {
      opacity:0.8;
    }
  }
  &.left {
    float: left;
  }
  &.right {
    float: right;
  }

  @each $name, $color_info in $colorscheme {
    $color: nth($color_info, 1);
    $alternate: nth($color_info, 2);

    &.#{$name} {
      background-color: $color;
      border-color: $color;
      color: $alternate;
    }
  }
  &.disabled {
    opacity:0.5;
    cursor: default;
    box-shadow:none;
    @include transition-none;
    transform: none;
    &:hover, &.hover, &:focus {
      background-color: $teal;
      box-shadow:none;
      transform: none;
      @include transition-none;
    }
  }

  &.inline {
    display: inline-block;
    line-height: inherit;
    padding: 0;
    color: $teal;
    background-color: transparent;
    border-color: transparent;
    &:hover, &.hover, &:focus {
      color: $lime;
      background-color: transparent;
      border-color: transparent;
    }
  }
  &.borderless {
    color: $gray;
    background-color: transparent;
    border-color: transparent;
    &:hover, &.hover, &:focus {
      background-color: transparent;
      opacity:0.8;
      color: $Text;
    }
    &.inline {
      margin: 0 10px;
    }
  }
  @include button-hover;
  &:hover, &.hover, &:focus {
    &.lime {
      border-color: $teal;
      background-color: $teal;
    }
    &.delete {
      border-color: $amaranth;
      background-color: $amaranth;
    }
  }
  i {
    margin-right: 9px;
  }

  &.marketing {
    // buttons on the marketing pages don't fit our general styles.
    display:block;
    font-size: 20px;
    margin: 20px auto 0 auto;
    @include border-radius-24;
    @include box-shadow-btn;
    max-width: 300px;
    &:hover, &.hover, &:focus {
      opacity: 0.9;
    }
    i {
      margin-right: 0;
      margin-left:6px;
      font-size:18px;
    }

    &.transparent {
      background: none;
      border-color: transparent;
      color: $teal;
      text-transform: none;
      &:hover, &.hover {
        color: $lime;
      }
    }
  }
  &.auth  {
    // buttons on the auth pages are slightly bigger.
    font-size: 18px;
    margin: 20px 0 0 0;
    width:75%;
    white-space: normal;
    @include border-radius-24;
    @include box-shadow-btn;
    &:hover{
      @include btn-hover-levitation-transform;
      @include box-shadow-btn-hover;
    }
    i {
      margin-right: 0;
      margin-left:6px;
      font-size:12px;
    }
    &.disabled {
      opacity:0.5;
      cursor: default;
      box-shadow:none;
      @include transition-none;
      transform: none;
      &:hover, &.hover, &:focus {
        background-color: $teal;
        box-shadow:none;
        transform: none;
        @include transition-none;
      }
    }
  }
  &.add-video-dropdown {
    padding: 15px 42px 15px 40px;
    border-radius: 12px;
    font-size: 17px;
    font-family: $font-family-sans-serif;
    font-weight: 900;

    @include respond-to(phone) {
      padding: 10px 25px 10px 21px;
      border-radius: 8px;
      font-size: 15px;
    }


    span.fa-chevron-down {
      margin-left: 9%;
    }
  }
}


.collapseButton-moreLess {
  color: $charcoal;
  @include font-weight-bold;
  &:before {
    display: inline-block;
    position: relative;
    content: "Less";
  }
  i {
    display: inline-block;
    margin-left: 10px;
    transform: rotate(-90deg);
  }
  &.collapsed {
    &:before {
      content: "More";
    }
    i {
      transform: rotate(90deg);
    }
  }
}

// Async Buttons
//
// Used to provide user interaction feedback for buttons that fire asynchronous events
//
// Example:
// <a class="button asyncButton" href="#" role="button">Link</a>
// <a class="button asyncButton active" href="#" role="button">Link</a>
//
// Styleguide asyncButton

.asyncButton {
  &.active {
    color: transparent;
    background-image: url(/images/loading_ellipsis.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px;
    pointer-events: none;
  }
}

.expandButton {
  display: flex;
  align-items: center;
  @include border-radius-24;
  @extend .bg-teal;
  font-size: $font-size-normal;
  text-align: left;
  @include no-underline;
  padding: 0 13px;
  height: 39px;
  @include transition(0.5s);
  overflow: hidden;
  white-space: nowrap;
}

.expandButtonNav {
  display: flex !important;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  font-size: $font-size-normal;
  @include respond-to(laptop, desktop) {
    height: $primary-nav-height;
  }
}

.expandButton-text {
  margin-left: 4px;
  display: none;
}

.button-text {
  margin-left: 4px;
}


.add-subtitle-selector {
  .form-group {
    color: #fff;
    background: $Primary;
    padding: 10px;
    border-radius: 12px;
    &.has-error {
      .helpBlock {
        color: #fff;
      }
    }
    button {
      box-shadow: none;
      background: none;
      &:focus {
        box-shadow: none;
        background: none;
      }
      &:hover {
        opacity: 0.8;
        color: $Primary900;
        box-shadow: none;
        background: none;
      }
    }
  }
}