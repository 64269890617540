@import "~animate-sass/animate";

@keyframes symbol {
    0% { transform: scale(0.6); opacity: 0; }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); opacity: 1;}
}

#amara-logo {
  &.animated {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    overflow: visible;
    .symbol {
      path {
        fill: #DDDDDD;
      }
      &.finale {
        path {
          fill: #DDDDDD !important;
        }
      }
    }
    #background-symbols {
      .symbol {
        opacity: 0;
      }
    }
    #final-symbols {
      transform-origin: 50% 50%;
      .finale {
        opacity: 0;
      }
    }
    #hands {
      transform:
        translate(450px,-60px)
        rotate(46deg);
      opacity: 0.0;
      #hand-top {fill: url(#grad1)}
      #hand-bottom {fill: url(#grad2)}
    }
  }
}

// Media Queries
@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {

}
