.presetOrCustom-item {
  padding-top: 4px;
}
.presetOrCustom-textInput {
  width: auto;
  flex-grow: 1;
  border-width: 0;
  padding: 0;
  border-bottom-width: 1px;
}
