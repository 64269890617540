.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  margin-top: 4px;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
  &.fixed {
    table-layout: fixed;
  }
  thead {

  }
  td, th {
    padding: 8px;
    vertical-align: top;
    text-align: left;
    &.nowrap {
      white-space: nowrap;
    }
  }
  th {
    border-bottom: 2px solid dark($snow);
  }
  &.striped {
    tr:nth-child(odd) {
      background: $white;
    }
    tr:nth-child(even) {
      background: $snow;
    }
  }
  // A secondary tbody is used to start new set of rows inside a table.  This means:
  // - The first row should look like a header row
  // - If the table is striped, the strips starting with the second row
  tbody.secondary :first-child td {
    padding-top: 32px;
    background: $white;
    border-bottom: 2px solid dark($snow);
    @include font-weight-bold;
  }
  &.striped tbody.secondary {
    tr:nth-child(even) {
      background: $white;
    }
    tr:nth-child(odd) {
      background: $snow;
    }
  }

  &.lined {
    td {
      border-bottom: 1px solid dark($snow);
    }
  }
  &.align-middle {
    td {
      vertical-align: middle;
    }
  }
  &.key-value {
    th {
      border: 0;
      padding: 0 20px 0 0;
    }
    td {
      padding: 0;
    }
  }
}
td.right {
  text-align: right;
}

table.historyTable {
  max-height: 250px;
  table-layout: fixed;
  tr {
    td {
      padding: 0px 10px;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        width: 50%;
      }
      &:last-child {
        width: 120px;
        text-align: right;
        i {
          margin-left: 10px;
          font-size: 18px;
          color: $gray;
        }
        i.icon {
          display: inline-block;
          width: 30px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}

.assignmentHistoryTable {
  @extend .table;
  @extend .table.striped;
  table-layout: fixed;
  td, th {
    padding: 4px 8px;
    line-height: 30px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      width: 50%;
    }
    &:last-child {
      width: 120px;
      text-align: right;

    }
  }
}

.simpleDashboardHistoryLanguageLink {
  text-align: center !important;
}

// it's okay to set a hard padding of 100px since this element is only present in large screens
.simpleDashboardHistoryDuration {
  text-align: right !important;
  padding-right: 5% !important;
}

.simpleDashboardHistoryDate {
  width: 200px !important;
}
