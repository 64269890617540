.amaraTooltip {
  cursor: pointer;
}

.amaraTooltip-tip {
  position: absolute;
  display: none;
  background: $white;
  border: 1px solid $snow;
  @include box-shadow;
  padding: 20px;
}


/*tooltip */
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: .90;
  width: 40vh;
  padding: 20px;
  background: $Info100;
  color: $Text;
  position: absolute;
  top: -140%;
  left: -25%;
  border-radius: 9px;
  font: 16px;
  transform: translateY(9px);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 3pxrgba(56,54,54,0.86);
  a {
    color: $Text;
    text-decoration: underline;
  }
}
// /* tooltip  after*/
// .tooltip::after {
//   content: " ";
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 12px 12.5px 0 12.5px;
//   border-color: $Accent transparent transparent transparent;
//   position: absolute;
//   left: 40%;
// }
.con-tooltip:hover {
  cursor: pointer;
  .tooltip{
    visibility: visible;
    transform: translateY(-10px);
    opacity: 1;
    transition: .3s linear;
    animation: 1s ease-in-out infinite  alternate;
  }
}
.bottom:hover {transform: translateY(6px);}
.bottom .tooltip::after{
  top:-1vh;
  left:40%;
  transform: rotate(180deg);
}
