
.dropdownMenu {
  position: absolute;
  display: none;
  @include box-shadow-darker;
  background: $white;
  border-radius: 12px;

  z-index: $z-index-menu;

  flex-direction: column;
  &.dropdown-navbar {
    top: 60px;
    width: 500px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    li {
      flex-basis: 48%;
      margin: 5px;
    }
    li:last-child {
        flex-basis:100%;
    }
    svg {
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 7px;
    }
  }
  &.add-video-dropdown-menu-button {
    width: 220px;
  }
  &.subtitlingServicesnavbar {
    width: 280px;
    margin-top: 12px;
  }
}

.dropdownMenu-item {
  &.separator {
    border-top: 1px solid dark($snow);
  }
  &.add-video-dropdown-li, &.subtitlingServicesnavbarDropdown-li {
    .dropdownMenu-link {
      color: $Text;
      font-family: $first-level-headings-font-family;
      font-weight: 900;
      font-size: 16px;
      padding: 20px 28px 20px 20px;
      .dropdownMenu-icon {
        top: 20px;
        left: 20px;
        font-size: 22px;

      }
      .dropdownMenu-text {
        margin-left: 40px;
         &.subtitlingServicestext {
          margin-left: 0px;
          display: block;
          }
          &.subtitlingServicestextdetails {
            font-family: $font-family-sans-serif;
            margin-left: 0px;
            font-size: 0.8em;
            font-weight: 400;
            text-align: left;
          }
      }
      &:hover, &:focus {
        color: $Text;
        background: $Primary050;
        outline: none;
        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

    }
    &.url {
      .dropdownMenu-link {
        &:before{
          position: absolute;
          top: 20px;
          content: url("../img/btn-video-link.svg");
        }
      }
    }
    &.multiple-url {
      .dropdownMenu-link {
        &:before{
          position: absolute;
          top: 20px;
          content: url("../img/btn-video-multiple-url.svg");
        }
      }
    }
    &.upload {
      .dropdownMenu-link {
        &:before{
          position: absolute;
          top: 16px;
          left: 21px;
          content: url("../img/btn-upload.svg");
        }
      }
    }
    &.gdrive {
      .dropdownMenu-link {
        &:before{
          position: absolute;
          top: 20px;
          content: url("../img/btn-video-drive.svg");
        }
      }
    }
    &.csv {
      .dropdownMenu-link {
        &:before{
          position: absolute;
          top: 20px;
          content: url("../img/btn-video-csv.svg");
        }
      }
    }
    &.videoAutocaptions {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 22px;
          width: 55px;
          right: 6px;
          content: url("../img/aod-services-ai-icon.svg");
        }
      }
    }
    &.videoCaptions {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 20px;
          right: 6px;
          content: url("../img/aod-services-cc-icon.svg");
        }
      }
    }
    &.videoSubtitles {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 20px;
          right: 6px;
          content: url("../img/aod-services-subtranslation.svg");
        }
      }
    }
    &.videoTranscript {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 20px;
          right: 20px;
          content: url("../img/aod-services-transcript-icon.svg");
        }
      }
    }
    &.textTranslation {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 20px;
          right: 20px;
          content: url("../img/aod-services-texttranslation-icon.svg");
        }
      }
    }
    &.allServices {
      .dropdownMenu-link {
        &:after{
          position: absolute;
          top: 17px;
          right: 14px;
          content: url("../img/aod-services-all.svg");
        }
      }
    }
  }
}

.dropdownMenu-link {
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  @include no-underline;
  color: inherit;
  @include font-weight-normal;
  padding: 4px 28px 4px 28px;
  color: $Text;
  font-size:14px;

  .dropdownMenu.no-icon & {
    padding: 4px;
  }
  &:hover {
    color: $Primary;
  }

  &:focus {
    background: $Primary;
    color: $white;
  }

  &.header {
    background: inherit;
    color: $gray;
    cursor: default;
  }

  &.disabled {
    color: light($gray);
    cursor: not-allowed;

    &:hover, &:focus {
      background: $white;
      color: light($gray);
    }
  }
}

.dropdownMenu-icon {
  position: absolute;
  left: 8px;
  top: 6px;
  font-size: 14px;
  line-height: $line-height-normal;
}


