.cookie-consent-cover {
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999998;
}

.cookie-consent-container {
  width: 855px;
  background: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  border: 3px solid $snow;
  box-shadow: 0px 15px 15px 0 rgba(0,0,0,0.35);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  z-index: 999999;

  span {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @include respond-to(desktop) {
    bottom: 50px;
  }

  @include respond-to(phone, tablet) {
    width: 350px;
    flex-direction: column;
  }
}

.cookie-consent-text-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  @include respond-to(phone, tablet) {
    margin: none;
  }
}

.cookie-consent-button-container {
  margin-left: 30px;
  display: flex;
  align-items: center;

  @include respond-to(phone, tablet) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}

.cookie-consent-button {
  margin-left: 20px;

  @include respond-to(phone, tablet) {
    margin-left: 10px;
    margin-right: 10px;
    width: 135px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.cookie-consent-details-container {
  width: 400px;
  background: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  border: 3px solid $snow;
  box-shadow: 0px 15px 15px 0 rgba(0,0,0,0.35);
  border-radius: 10px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  z-index: 999999;

  span {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .padded-section {
    padding-left: 25px;
    padding-right: 25px;
  }

  @include respond-to(desktop) {
    bottom: 50px;
  }

  @include respond-to(phone, tablet) {
    width: 350px;
  }
  .accordion-section {
    margin: auto auto 0 0;
  }
}

.cookie-consent-details-accordion-title {
  background: none;
  color: $charcoal;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px;
  padding-left: 25px;

  span.toggle {
    top: 0px;
    right: 15px;
  }
}

.cookie-consent-details-toggle-title {
  width: 255px;
  display: inline-block;

  @include respond-to(phone, tablet) {
    width: 205px;
  }
}

.cookie-consent-details-text {
  padding-left: 25px;
  color: $charcoal;
}

.cookie-consent-details-footer {
  background: $snow;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}

.cookie-consent-details-button {
  width: 125px;
  margin-left: 6px;
  margin-right: 6px;
}

// created a simple implementation of a slider here instead of using the one we already have
// with ui.forms.widget.SwitchInput -- I think it's super pain to make that one work with
// the use case here for the cookie consent screen (since we're not really using a form for this one)

/* The switch - the box around the slider */
.cookie-consent-details-toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 34px;
  top: 10px;
}

/* Hide default HTML checkbox */
.cookie-consent-details-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  display: inline-block;
}

/* The slider */
.cookie-consent-details-toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.cookie-consent-details-toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  top: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.cookie-consent-details-toggle-switch input:checked + .slider {
  background-color: $teal;
}

.cookie-consent-details-toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.cookie-consent-details-toggle-switch .slider.round {
  border-radius: 34px;
}

.cookie-consent-details-toggle-switch .slider.round:before {
  border-radius: 50%;
}