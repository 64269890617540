// Card/gutter width at various browser sizes.
//
// We calculate these as so that:
// (video_card_width + 2px for borders) * videos_per_row + (gutter_width * videos_per_row - 1) == container_width

$video-card-width: (
   desktop: 288px,
   laptop: 288px,
   tablet: 320px,
);

$video-card-gutter: (
   desktop: 70px,
   laptop: 50px,
   tablet: 76px,
   phone: 0,
);

@mixin video-card-outer-width($breakpoint) {
  @if($breakpoint != phone) {
    width: map-get($video-card-width, $breakpoint) + 2px;
  } @else {
    width: 100%;
  }
}

@mixin video-card-image-size($breakpoint) {
  @if($breakpoint != phone) {
    width: map-get($video-card-width, $breakpoint);
    height: map-get($video-card-width, $breakpoint) * 9 / 16;
  } @else {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
}

@mixin video-card-grid-gutters($breakpoint) {
  $width: map-get($video-card-gutter, $breakpoint);

  margin: -20px (-$width / 2);

  > * {
    margin: 20px $width / 2;
  }
}

.videoCard2-grid {
  display: flex;
  flex-flow: wrap;
  @include video-card-grid-gutters(desktop);

  .assignmentCard {
    @include respond-to(phone) {
      width: 100%;
    }
  }
}

.videoCard2 {
  display: block;
  @include video-card-outer-width(desktop);
  @include border-radius-12;
  @include box-shadow;
  @include transition(0.2s);
  &:hover {
    @include box-shadow-hover;
    opacity:0.9;

  }
}

.videoCard2-thumbnail {
  display: block;
  position: relative;
  @include video-card-image-size(desktop);
  @include border-radius-12;
}

.videoCard2-image {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include border-radius-12;
}

@each $breakpoint in (laptop, tablet, phone) {
  @include respond-to($breakpoint) {
    .videoCard2-grid {
      @include video-card-grid-gutters($breakpoint);
    }

    .videoCard2 {
      @include video-card-outer-width($breakpoint);
    }

    .videoCard2-thumbnail {
      @include video-card-image-size($breakpoint);
    }
  }
}

.videoCard2-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: $line-height-normal + 8px;
  padding: 4px 8px;
  color: $white;
  background: rgba(black, 0.8);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  &.with-subtitle {
    height: $line-height-normal * 2 + 8px;
  }
}

.videoCard2-main {
  display: flex;
  width: 100%;
  font-size: $font-size-small;
  @include ellipsis;
}

.videoCard2-title {
  font-size: $font-size-normal;
  flex-grow: 1;
  @include ellipsis;
}

.videoCard2-title2 {
  justify-content: flex-end;
  font-size: $font-size-tiny;
  margin-left: 20px;
}

.videoCard2-subtitle {
  display: flex;
  width: 100%;
  font-size: $font-size-tiny;
  @include ellipsis;
}

.videoCard2-subtitle1 {
  flex-grow: 1;
}

.videoCard2-subtitle2 {
  justify-content: flex-end;
  margin-left: 20px;
}

.videoCard2-duration {
  position: absolute;
  top: 3%;
  right: 2%;
  padding: 2px 8px;
  font-size: $font-size-small;
  background: rgba(black, 0.3);
  color: $white;
  @include border-radius-12;
}

.videoCard2-language {
  position: absolute;
  top: 0;
  left: 0;
  font-size: $font-size-small;
  color: $white;
  background: rgba(black, 0.5);
  padding: 2px 8px;
}

.videoCard2-cta {
  display: flex;
  align-items: center;
}

@mixin videocard-cta-button {
  line-height: 24px;
  padding: 8px 0;
  color: $charcoal;
  @include font-weight-normal;
  text-align: center;
  cursor: pointer;
  @include no-underline;
  @include transition(0.2s);

  &:hover, &:active {
    color: $Primary;
  }
  // Hack to align our icons correctly.  Long-term, maybe we should switch to font-awesome for these too
  .icon {
    position: relative;
    top: -1px;
  }
}

.videoCard2-ctaMain {
  @include videocard-cta-button;
  flex-grow: 1;
}

.videoCard2-checkbox {
  border-bottom-left-radius: 12px;
  label {
    line-height: 40px;
    min-height: 40px;
  }
  .checkbox-icon {
    margin: 0 8px;
    &:hover{
      border: 1px solid $Primary;
      background:$Primary100;
    }
  }

  &:hover {
    opacity:0.7;
  }
}

.videoCard2-contextMenu {
  @include videocard-cta-button;
  width: 30px;
}

// TODO: switch styles over to videoCard2, then remove the rest of the code in this file
.videoCard {
  display: block;
  position: relative;
  height: auto;
  margin: 0 0 40px 0;
  overflow: hidden;
  box-sizing: border-box;
  @extend .col-xs-12;
  @extend .col-sm-6;
  @extend .col-md-4;

  .button {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  &.pending {
    .button {
      background-color: $gray;
      i { display: none; }
    }
  }
}

.videoCard-header {
  position: relative;
  height: 30px;
  margin: 0;
  padding: 0;
  border: 1px solid light($gray);
  border-bottom: none;
}
.videoCard-footer {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid light($gray);
  border-top: none;
  p {
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    font-size: 12px;
  }
}
.videoCard-duration {
  position: absolute;
  right: 5px;
  top: 5px;
  padding-left: 20px;
  font-size: 12px;
  &:before {
    @extend .far;
    @extend .fa-clock;
    padding-right: 5px;
  }
}
.videoCard-priority {
  display: inline-block;
  height: 31px;
  line-height: 31px;
  padding: 0 10px;
  background-color: $amaranth;
  i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    font-size: 20px;
  }
}

.videoCard-thumbnail {
  position: relative;
  display: block;
}

.videoCard-caption {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 60px 30px 10px 10px;
  color: #ffffff;
  background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.7));
}
.videoCard-title {
  @include clamp(2, 1.2);
}
.videoCard-subtitle {
  margin-top: 3px;
  margin-bottom: 0;
}


.videoCardSimple {
  display: block;
  position: relative;
  width: 260px;
  height: auto;
  min-height: 150px;
  @include border-radius-12;
  margin: 10px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: $charcoal;
  color: #ffffff;
  text-align: left;
  @include no-underline;
  @include box-shadow;
  @include transition(0.2s);
  &:after {
    font-family: 'amara';
    @extend .icon-forward:before;
    @include no-underline;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #ffffff;
    font-size: 25px;
  }
  &:hover{
    @include box-shadow-hover;
    opacity:0.8;
  }
}

.videoCardSimple-summary {
  display: flex;
  @include no-underline;
  color: $Text;
  background-color:$Grey200;
  border: 1px solid $Grey400;
  box-shadow:none;
  &:after {
    display: none;
  }
  &:hover{
    box-shadow: none;
    opacity:1;
  }
}

.videoCardSimple-summaryCount {
  margin: auto;
  font-size: 40px;
  margin-left: 40px;
  margin-right: 10px;
  @include font-weight-light;
}

.videoCardSimple-summaryLabel {
  @extend .h2;
  margin: auto;
  margin-right: 40px;
}

.videoCardSimple-caption {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 60px 30px 10px 10px;
  color: #ffffff;
  background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.7));
}
.videoCardSimple-title {
  @include clamp(2, 1.2);
}
.videoCardSimple-subtitle {
  margin-top: 3px;
  margin-bottom: 0;
}

.videoPageMargin {
  padding: 0px 20px 20px 20px;
}

.videoPageMarginSubs {
  padding: 5px 20px 20px 20px;
}