canvas {
  width: 200px;
  height: 200px;
}

// Media Queries

@media(max-width: $screen-md-max) {

}

@media(max-width: $screen-sm-max) {

}

@media(max-width: $screen-xs-max) {

}
