ngblock-center {
  display: grid;
  justify-content: center;
}

.block-wrap {
  display: flex;
  margin-right: -80px;
  flex-wrap: wrap;
  > * {
    margin-right: 80px;
  }
}

.width-100 {
  width: 100%;
}

.responsive {
  max-width: 100%;
}

.width-small {
  max-width: $container-sm;
}

.pad-all {
  padding: 20px;
}

.scroll-area {
  max-height: 300px;
  overflow-y: auto;
}

@each $dir in ('top', 'right', 'bottom', 'left') {
  .pad-#{$dir} {
    padding-#{$dir}: 20px;
  }
  .margin-#{$dir} {
    margin-#{$dir}: 20px;
  }
}

.split {
  display: flex;
  margin-right: -20px;
  flex-wrap: wrap;
}

.split-item {
  flex: 0 0 50%;
  padding-right: 20px;

  &.third {
    flex: 0 0 33.33%;
  }

  &.main {
    flex: 1 0 50%;
  }

  &.sidebar {
    flex: 0 0 300px;
  }
}

@include respond-to(phone) {
  .split {
    display: block;
    margin-right: 0;
  }

  .split-item {
    margin-right: 0;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divide {
  display: flex;
  justify-content: space-between;
  align-items: start;

  &.vertical {
    flex-direction: column;
  }
}
