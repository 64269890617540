// Code to implement the overall flow of the page, especially the sticky
// footer.  It would be nice to key this off a class for <body> but that's not
// possible because of the way the body-extra block works.
html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 145px;
  &.homepage, &.page {
    padding-top: 50px;
  }
}

#page-content {
  flex: 1;
  overflow: hidden;
}

// Content padding and load
.content {
  position: relative;
  padding: 20px;
}

.text-section {
  padding-bottom: 50px;
}

.nowrap {
  white-space: nowrap;
}
