.modal {
  display: none;
  position: fixed;
  z-index: $z-index-modal;
  background: $white;
  height: auto;
  overflow: auto;
  @include border-radius-12;
  padding: 25px;
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.13);
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
  width: 100%;
  flex-direction: column;

  // Hack to override bootstrap's styles
  .tab-pane {
    padding: 0;
  }
}

// refactor from #6775 - this allows us to choose the display attribute for modals
.modal-visible {
  display: flex;
}

@include respond-to(laptop, 0) {
  .modal {
    width: 700px;
    margin: 0 auto;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    &.modal-small {
      width: 500px;
    }
  }
}
@include respond-to(tablet) {
  .modal {
    width: 700px;
    margin: 0 auto;
    height: auto;
    max-height: 90vh;
    overflow-y: scroll;
    &.modal-small {
      margin: 0 auto;
      width: 500px;
    }
  }
}
@include respond-to(phone) {
  .modal {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 0px;
    &.modal-small {
      height: 100%;
    }
  }
}
.a
.ajaxForm {
  .modal-content {
    height: 100%;
    overflow-y: auto;
    padding: 20px 20px 0 20px; // See the back below for how we handle the bottom 20px of padding
    &:after {
      // Hack to simulate padding-bottom: 20px that works with overflow: auto;
      display: table;
      content: ' ';
      height: 20px;
    }
  }
}

.modal-backdrop {
  position: fixed;
  display: none;
  z-index: $z-index-overlay;
  background: rgba(dark($gray), 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal-tabs {
  padding: 0;
  margin: 10px 0;
}

.modal-title {
  color: $Primary;
  padding: 12px 0px;
  margin: 0;
  font-family: $font-family-sans-serif;
  @include font-weight-bold;
  text-align: left;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 28px;
  line-height: 34px;
  @include respond-to(phone) {
    text-align: center;
  }

}

.modal-header {
  margin: 5px 0 20px 0;
  color: $Primary;
  font-family: $font-family-sans-serif;
  @include font-weight-bold;
}

.modal-subheader {
  margin: -20px 20px 20px 0px;
  color: $gray;
}

.modal-footer {
  bottom: 0;
  height: 100px;
  padding: 8px;
  text-align: right;
  .modal-close {
    margin-right: 2%;
  }
}

.modal-closeButton {
  font-size:30px;
  -webkit-text-stroke: 4px white;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  color: $Grey800;
  padding: 12px;
  line-height: 20px;
  @include transition(0.5s);

  &:hover {
    color: $Grey600;
  }
  @include respond-to(phone) {
    left: 0;
    right: auto;
  }
}

.modal-section {
  padding: 20px 0;

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
}
