.title-underline-pricing {
  width: 225px;
  background: url(../img/title-underline.svg) no-repeat;
  background-size: cover;
  margin-bottom: 0.5em;
  height: 22px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}

.subtitlingPlatform-pricingTable {
  padding: 0;
}

.pricingTable {
	--gap: 18px;
	--nb-items: 4;
	display: flex;
	justify-content: center;
	/* Make it wrap in case we have too many items */
	flex-wrap: wrap;
	gap: var(--gap);

	/* Just design purpose */
	max-width: 100%;
	padding: 32px;
  @media(max-width: $screen-sm-max) {
    --nb-items: 1;
  }
}

.pricingTable-Item:nth-last-child(4):first-child,
.pricingTable-Item:nth-last-child(4):first-child ~ .pricingTable-Item {
	--nb-items: 4;
  @media(max-width: $screen-md-min) {
    --nb-items: 2;
  }
  @media(max-width: $screen-sm-max) {
    --nb-items: 1;
  }
}

.pricingTable-Item:nth-last-child(5):first-child,
.pricingTable-Item:nth-last-child(5):first-child ~ .pricingTable-Item {
	--nb-items: 5;
  @media(max-width: $screen-md-min) {
    --nb-items: 2;
  }
  @media(max-width: $screen-sm-max) {
    --nb-items: 1;
  }
}

/**
 * Flex direction column to put our
 * footer at the very bottom.
 */
.pricingTable-Item {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
  position: relative;
  overflow: visible;
	/* Be careful with max-content value */
	// min-width: max-content;
	/**
	 * The calc here is a way to keep thing out
	 * of the wrapping behavior for a while.
	 * Above 5 items it stops and go back
	 * to --nb-items = 3
	 */
	flex-basis: calc( calc(100% - (var(--gap) * var(--nb-items)) )/var(--nb-items) );
  border: 1px solid $Accent200;
  border-radius: 12px;
  // @include box-shadow;
  .pricingTable-Heading {
    min-height: 144px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 0px;
    border-radius: 12px;
    position: relative;
    .pricingTable-Title {
      font-size: 30px;
    }
    .pricingTable-Subtitle {
      margin-top: 3px;
      font-size: 18px;
      line-height: 24px;

    }
  }
  .pricingTable-Price {
    position:relative;
    padding: 25px 20px 20px 20px;
    min-height: 94px;
    &.public {
      .pricingTable-Price-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        --gap: 8px;
        --nb-items: 2;
        .public-price {
          width: 28%;
          .pricingTable-nb {
            font-size: 26px;
            color: $Accent;
          }
          small {
            font-size: 14px;
          }
        }
        .public-users {
          font-size: 14px;
        }
      }
    }
    &.plus {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      --gap: 8px;
      --nb-items: 2;
      .plus-price {
        width: 28%;
        .pricingTable-nb {
          font-size: 30px;
        }
        small {
          font-size: 16px;
        }
      }
      .plus-users {
        font-size: 14px;
        .pricingTable-users {
          font-family: $first-level-headings-font-family;
          font-weight: bold;
        }
      }
    }
    .contact-us {
      font-size: 26px;
    }
  }
  .pricingTable-button {
    padding: 10px 20px;
    .pricingTableButton {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      font-size: 1.1em;
    }
  }
  .pricingTable-Features {
    padding: 20px;
    .pricingTableDetailsIcon {
      margin-right: 2%;
    }
  }
  .pricingTable-Footer {
    padding: 20px;
  }

  &.free {
    .pricingTable-Heading {
      border-top: 6px solid $Success400;
      .pricingTable-Title {
        color: $Accent;
      }
    }

  }
  &.enterprise {
    .pricingTable-Heading {
      border-top: 6px solid $Info500;
      .pricingTable-Title {
        color: $Accent;
      }
    }
  }
  &.integrated {
    .pricingTable-Heading {
      border-top: 6px solid $DarkModeBG;
      .pricingTable-Title {
        color: $Accent;
      }
    }

  }
}
.pricingTable-Item.amara-plus {
  position: relative;
  .most-popular {
    position: absolute;
    z-index: 9;
    top: -6px;
    text-transform: uppercase;
    font-family: $first-level-headings-font-family;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 11px;
    line-height: 16px;
    background-color: #F5C2F5;
    color: #542A46;
    width: 106px;
    left: 50%;
    margin-left: -53px;
    border-radius: 6px;
  }
  .pricingTable-Heading {
    border-top: 6px solid $Accent400;
    background-color: $Accent100;
    .pricingTable-Title {
      color: $Accent;
    }
  }
}

.pricingTable-Item > * {
	padding: 4px;
}

.pricingTable-Item p,
.pricingTable-Item ul,
.pricingTable-Item h2 {
	margin-top: 0;
	margin-bottom: 0;
}
.pricingTable-Feature {
	font-size: .875em;
	padding: 8px 0;
	list-style: none;
}


.pricingTable-Footer {
	padding-top: 0;
	margin-top: auto;
	text-align: center;
}



.pricingTable-Trial {
	font-size: .75em;
	color: #777;
}


.pricingTableDetailsContainer {
  display: flex;
  justify-content: center;
}

.pricingTableDetails {
  width: auto;
  border-collapse: collapse;
  position: relative;
}

.pricingTableDetailsIcon {
  color: $Success;

  &.minus {
    color: $gray;
  }
}

.pricingTableDetailsHeader {
  position: sticky;
  top: 0px;
  font-size: 20px;
  background-color: white;
}

.pricingTableDetails th+th {
  width: 160px;
  text-align: center;
}

.pricingTableDetails td {
  sup {
    top: -0.2em;
  }

}

.pricingTableDetails td a {
  @include border-radius-24;
  @include box-shadow-btn;
  &:hover{
    @include btn-hover-levitation-transform;
    @include box-shadow-btn-hover;
  }
  i {
    margin-right: 0;
    margin-left:6px;
    font-size:12px;
  }
}

.indentedIcon {
  padding-left: 20px;
  padding-right: 10px;
}

.pricingTableDetails td+td {
  text-align: center;
}

.borderless td {
  border: none;
}

.pricingTableDetailsButton {
  width: 160px;
}

.pricingAccordionTrigger {
  margin: 5px;
  font-family: $first-level-headings-font-family;
  font-weight: bold;
  width: 160px;
  font-size: 20px;
}

.pricingAccordionDescription {
  margin-bottom: 25px;
}

.pricingTableDetailsAccordion {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.pricingTableDetailsAccordion tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pricingTableDetailsAccordion tr td+td {
  width: 5%;
  text-align: center;
}

.pricingTableTestimonial {
  padding: 25px;

  > div {
    background-color: $white;
    display: flex;
    flex-flow: column;

    > p {
      margin-top: 15px;
      margin-bottom: 40px;
    }
  }
}

#faq {
  padding-top: 12%;
  .title-underline  {
    margin: 0 auto;
  }
}

.custom-shape-divider-top-1677538759 {
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1677538759 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 13px;
}

.custom-shape-divider-top-1677538759 {
  .shape-fill {
    fill: #FCF7FA;
  }
}

.arrow-testimonials {
  width: 13%;
  margin: 0 auto;
  display: block;
}
